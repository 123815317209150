import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Text,
  Grid,
  GridItem,
  Icon,
  Box,
  Button,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';

import { GenericModal } from '@/features/registers';
import { useUnidadesQuery } from '@/hooks';
import { useInstanciaStore, useUnidadeStore, useUserStore } from '@/stores';
import { ChangeEnvironmentModal } from './components/ChangeEnvironmentModal';

const styles = {
  root: {
    alignItems: 'center',
    background: 'transparent',
    borderRadius: '10px',
    cursor: 'pointer',
    display: 'flex',
    height: '52px',
    marginRight: '50px',
    padding: '0px 10px',
    width: 'fit-content',
  },
};

export function UnidadeSelect() {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  const uniRef = useRef(null);
  const [name, setName] = useState<string>('');
  const [openModal, setOpenModal] = useState<any>(null);
  const [unidades, setUnidades] = useState<any[]>([]);
  const [isSelect, setIsSelect] = useState<boolean>();

  const setUnidade = useUnidadeStore(state => state.setUnidade);
  const unidadeStore = useUnidadeStore(state => state.unidade);
  const instanceStore = useInstanciaStore(state => state.instancia);

  const { data } = useUnidadesQuery();

  const handleChangeUnidade = useCallback(
    (uni: any) => {
      setOpenModal(uni);
    },
    [setUnidade],
  );

  const handleSelectOpen = useCallback(() => {
    setIsSelect(!isSelect);
  }, [isSelect]);

  useEffect(() => {
    if (data) {
      if (data.length > 0) {
        const pro = data[0];
        if (!unidadeStore) {
          setName(pro.label);
          setUnidade({
            id: pro.value,
            descricao: pro.label,
            instanciaSaudeId: pro.instance,
          });
        } else {
          setName(unidadeStore.descricao);
          setUnidade(unidadeStore);
        }
        setUnidades(data);
      } else {
        setName('');
        setUnidade(null);
        setUnidades([]);
      }
    }
  }, [data, setUnidade]);

  const mountInitial = useRef(true);
  useEffect(() => {
    if (mountInitial.current) {
      mountInitial.current = false;
      return;
    }
    console.log('instanceStore');
    setUnidade(null);
  }, [instanceStore]);

  useEffect(() => {
    const pageClickEvent = (e: any) => {
      /* @ts-ignore */
      if (uniRef.current !== null && !uniRef?.current?.contains(e.target)) {
        setIsSelect(!isSelect);
      }
    };
    if (isSelect) {
      window.addEventListener('click', pageClickEvent);
    }
    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isSelect]);

  // if (user?.type === 'medico') return null;

  return (
    <div ref={uniRef} style={styles.root}>
      <Flex position="relative">
        <Grid
          width={{ lg: 'auto', sm: '1rem', md: '5rem' }}
          templateColumns="repeat(4, 1fr)"
          alignItems="center"
          onClick={handleSelectOpen}
        >
          {!isMobile && (
            <GridItem
              colSpan={3}
              display="flex"
              alignItems="center"
              flexDirection="column"
              position="relative"
            >
              <Text
                fontFamily="Montserrat"
                fontSize={{ lg: '14', md: '8' }}
                fontStyle="normal"
                fontWeight="600"
              >
                {name || 'Unidade:'}
              </Text>
              {/* <Text
                fontSize={{ lg: '14', md: '6' }}
                fontFamily="Montserrat"
                fontStyle="normal"
                fontWeight="500"
              >
                {`${name?.substring(0, 10)}...`}
                {/* {name}
              </Text> */}
            </GridItem>
          )}
          <GridItem colSpan={1} display="flex">
            <Icon
              as={FiChevronDown}
              color="brand-dark.600"
              fontSize="20px"
              fontWeight="400"
            />
          </GridItem>
        </Grid>
        {isSelect && (
          <Box
            bg="white"
            borderRadius="10px"
            boxShadow="0.8px 0.8px 7px -2px rgba(5, 6, 27, 0.25)"
            marginLeft="-1"
            position="absolute"
            // padding="10px"
            top="39px"
            zIndex="3"
            width="auto"
          >
            {unidades.map((uni, index) => (
              <Button
                key={uni.value}
                bg="white"
                width="100%"
                color="brand-dark.300"
                fontFamily="Rubik"
                fontWeight="sm"
                borderBottom={
                  index === unidades.length - 1 ? 'none' : '1px solid #C2C2C2'
                }
                borderRadius="none"
                onClick={() => handleChangeUnidade(uni)}
              >
                <Text textAlign="left" width="100%">
                  {uni.label}
                </Text>
              </Button>
            ))}
          </Box>
        )}
      </Flex>
      <GenericModal
        open={openModal && openModal?.value}
        title="Alterar unidade"
        onClose={() => setOpenModal(false)}
      >
        <ChangeEnvironmentModal
          onChange={() => {
            setName(openModal.label);
            setUnidade({
              id: openModal.value,
              descricao: openModal.label,
              instanciaSaudeId: openModal.instance,
            });
            setIsSelect(false);
            setOpenModal(null);
          }}
          title="Unidade"
          onCancel={() => setOpenModal(null)}
        />
      </GenericModal>
    </div>
  );
}
