import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useMutation } from 'react-query';

import { api } from '@/lib';

async function recoveryPassRequest({
  password,
  tokenNovo,
  username,
}: any): Promise<any> {
  const token = tokenNovo.replace(/\s/g, '+');

  const response = await api.post(`/usuario/recuperar-senha`, {
    senha: password,
    token,
    username,
  });
  return response.data;
}

export function useRecoveryPassMutation() {
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const mutation = useMutation(
    ({ password, tokenNovo, username }: any) =>
      recoveryPassRequest({ password, tokenNovo, username }),
    {
      onSuccess: response => {
        const { res } = response;
      },
      onError: error => {
        if (axios.isAxiosError(error)) {
          toast({
            title: 'Ocorreu um erro. Tente novamente mais tarde.',
            status: 'error',
          });
        } else {
          toast({
            title: 'Servidor não disponível no momento',
            status: 'error',
          });
        }
      },
    },
  );

  return mutation;
}
