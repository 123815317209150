import {
  Avatar,
  Badge,
  Box,
  Icon,
  IconButton,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { IoTriangleOutline } from 'react-icons/io5';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useNotificationsTableQuery } from '@/features/notifications';
import { useUserStore } from '@/stores';
import { NotificationItem } from './NotificationItem';

export function NotificationsButton() {
  const user = useUserStore(state => state.user);
  const {
    data: notifications,
    isLoading,
    refetch,
  } = useNotificationsTableQuery();

  useEffect(() => {
    setTimeout(() => {
      refetch();
    }, 500);
  }, []);

  const hasRecentNotification = useMemo(
    () =>
      notifications
        ?.map(notification => notification.recente)
        .some(value => value),
    [notifications],
  );

  return (
    <Popover placement="bottom-end">
      {/* @ts-ignore */}
      <PopoverTrigger>
        <Box position="relative" justifyItems="center">
          <IconButton
            aria-label="Ver notificações"
            icon={<Avatar size="md" src={user?.avatar} name={user?.name} />}
            isDisabled={isLoading}
            w="60px"
            h="60px"
            background="transparent"
          />
          {(user?.type === 'medico' ||
            user?.type === 'paciente' ||
            user?.type === 'assistente') && (
            <>
              {hasRecentNotification && (
                <Badge
                  bg="brand-orange.400"
                  w="2"
                  h="2"
                  borderRadius="full"
                  position="absolute"
                  right="-1"
                  bottom="0"
                />
              )}

              <Icon
                as={IoTriangleOutline}
                color="#6A6A6A"
                w="4"
                h="4"
                borderRadius="full"
                position="absolute"
                right="-1"
                bottom="-2"
              />
            </>
          )}
        </Box>
      </PopoverTrigger>

      {(user?.type === 'medico' ||
        user?.type === 'paciente' ||
        user?.type === 'assistente') && (
        <Portal>
          <Box zIndex="popover" w="full" h="full" position="fixed">
            <PopoverContent
              width="inherit"
              bg="white"
              borderRadius="20px"
              overflow="hidden"
            >
              <PopoverHeader
                pl="8"
                pt="4"
                color="brand-dark.300"
                fontWeight="semibold"
              >
                Notificações
              </PopoverHeader>
              <PopoverBody p="0" w="min(50vw, 390px)">
                {notifications && notifications.length > 0 ? (
                  notifications.slice(0, 10).map(notification => {
                    return (
                      <NotificationItem
                        key={notification.id}
                        notification={notification}
                      />
                    );
                  })
                ) : (
                  <Text py="4" textAlign="center">
                    Nenhuma notificação!
                  </Text>
                )}

                <Link
                  as={ReactRouterLink}
                  to="/app/notifications"
                  py="2"
                  color="#959595"
                  fontSize="xs"
                  fontWeight="semibold"
                  textAlign="center"
                  textTransform="uppercase"
                  display="block"
                >
                  Ver tudo
                </Link>
              </PopoverBody>
            </PopoverContent>
          </Box>
        </Portal>
      )}
    </Popover>
  );
}
