import { formatCPF, formatDate } from '@/utils';
import {
  VStack,
  SimpleGrid,
  Grid,
  GridItem,
  Text,
  Heading,
} from '@chakra-ui/react';

interface Props {
  secretary: any;
}

const FormatData = ({ label1, value1, label2, value2 }: any) => {
  return (
    <Grid width="100%" templateColumns="repeat(4, 1fr)" gap={4}>
      <GridItem colSpan={2} textAlign="left" justifyContent="flex-start">
        <Heading as="h6" size="sm" color="#707070">
          {label1}
        </Heading>
        <Text>{value1}</Text>
      </GridItem>
      <GridItem colSpan={2}>
        <Heading as="h6" size="sm" color="#707070">
          {label2}
        </Heading>
        <Text>{value2}</Text>
      </GridItem>
    </Grid>
  );
};

export function ViewSecretaryInfo({ secretary }: Props) {
  return (
    <VStack spacing="4">
      {secretary && secretary.primeiroAcesso ? (
        <>
          <FormatData
            label1="Email"
            value1={secretary && secretary.email}
            label2="Celular"
            value2={secretary && secretary.celular}
          />
          <FormatData
            label1="Código Validação"
            value1={secretary && secretary.codigoUsuario}
            label2="Criado em"
            value2={secretary && formatDate(new Date(secretary.dataCriacao))}
          />
        </>
      ) : (
        <>
          <FormatData
            label1="E-mail"
            value1={secretary && secretary.email}
            label2="Celular"
            value2={secretary && secretary.celular}
          />

          <FormatData
            label1="CPF"
            value1={secretary && formatCPF(secretary.cpf)}
            label2="Sexo"
            value2={secretary && secretary.sexo[0]?.label}
          />

          <FormatData
            label1="Data de Nascimento"
            value1={secretary && formatDate(new Date(secretary.dataNascimento))}
            label2="Usuário"
            value2={secretary && secretary.userName}
          />

          <FormatData
            label1="Endereço"
            value1={secretary && secretary.endereco}
            label2="Número"
            value2={secretary && secretary.numero}
          />

          <FormatData
            label1="Bairro"
            value1={secretary && secretary.bairro}
            label2="CEP"
            value2={secretary && secretary.cep}
          />

          <FormatData
            label1="Cidade"
            value1={secretary && secretary.cidade}
            label2="Estado"
            value2={secretary && secretary.estado}
          />

          <div style={{ marginTop: 15 }} />
          {secretary?.dataCriacao && (
            <FormatData
              label1="Criado em:"
              value1={secretary && formatDate(new Date(secretary.dataCriacao))}
              label2="Atualizado em:"
              value2={
                secretary?.dataEdicao &&
                formatDate(new Date(secretary.dataEdicao))
              }
            />
          )}
        </>
      )}
    </VStack>
  );
}
