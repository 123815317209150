export const getYearsOld = (date: string) => {
  const nascimento = date.replace(/-/g, '/');
  const d = new Date();
  const yearNow = d.getFullYear();
  const monthNow = d.getMonth() + 1;
  const dayNow = d.getDate();

  const dayBirth = date.slice(0, 2);
  const monthBirth = date.slice(3, 5);
  const yearBirth = date.slice(6, 11);

  let age: any = yearNow - yearBirth;
  if (monthNow < monthBirth || (monthNow === monthBirth && dayNow < dayBirth)) {
    age -= 1;
  }

  return age;
};
