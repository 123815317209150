import { useMemo, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  SimpleGrid,
  Text,
  useToast,
} from '@chakra-ui/react';
import { formatDate } from '@/utils';
import { getYearsOld } from '@/utils/getYearsOld';
import dayjs from 'dayjs';

interface UserInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  patient: any | null;
}

export function PatientScreenInfoModal({
  isOpen,
  onClose,
  patient,
}: UserInfoModalProps) {
  const cids = patient?.anamneses?.cid;
  const lastConsult = patient?.anamneses?.dataAtendimento;
  let formattedToday = null;

  if (lastConsult) {
    const data = new Date(lastConsult);
    formattedToday = useMemo(
      () => formatDate(data, "dd 'de' MMMM 'de' yyyy"),
      [data],
    );
  }

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const OpenConsult = () => {
    const dateFormat = formatDate(patient.start, "dd 'de' MMMM 'de' yyyy");
    const dateConsult = new Date(patient.start);
    const hourConsult = `${patient.start.getHours()}h${
      (patient.start.getMinutes() < 10 ? '0' : '') + patient.start.getMinutes()
    }`;
    if (
      dateConsult >
      new Date(new Date().setMinutes(new Date().getMinutes() + 60))
    ) {
      toast({
        title: `A consulta está marcada para o dia ${dateFormat} às ${hourConsult}.`,
        status: 'error',
      });
    } else if (
      dateConsult <
      new Date(new Date().setMinutes(new Date().getMinutes() - 60))
    ) {
      toast({
        title: `A consulta estava marcada para o dia ${dateFormat} às ${hourConsult}.`,
        status: 'error',
      });
    } else {
      window.open(patient.urlVideo, '_blank');
    }
  };

  const age =
    getYearsOld(
      dayjs(patient?.resource?.dataNascimento).format('DD-MM-YYYY'),
    ) || 0;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
      <ModalOverlay />
      <ModalContent p="8" borderRadius="20px">
        <ModalHeader as={HStack} spacing="6" alignItems="flex-start">
          <Image
            src={patient?.urlPhoto}
            alt={patient?.title}
            borderRadius="full"
            boxSize="70"
          />

          <Box>
            <Text color="brand-dark.300" fontSize="2xl" fontWeight="medium">
              {patient?.title}
            </Text>
            <Text mt="1" color="#bfbfbf" fontSize="md" fontWeight="medium">
              {age} anos
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="8">
          <Text mb="6" color="brand-dark.200" fontSize="sm">
            <Text as="strong">Profissional: </Text>

            {patient?.nomeMedico}
          </Text>

          <Text mb="6" color="brand-dark.200" fontSize="sm">
            <Text as="strong">Unidade de Saúde: </Text>

            {patient?.nameUnidade}
          </Text>
          <Flex align="flex-start" justify="space-between">
            <Text mb="6" color="brand-dark.200" fontSize="sm">
              <Text as="strong">Consulta: </Text>
            </Text>

            <Text
              as="strong"
              color="brand-dark.300"
              fontSize="sm"
              textTransform="uppercase"
            >
              {patient?.statusConsulta === 5
                ? 'NEGADO'
                : patient?.statusConsulta === 6
                ? 'CONCLUÍDO'
                : patient?.statusConsulta !== 0
                ? 'PENDENTE'
                : patient?.consultation
                ? 'ON-LINE'
                : 'PRESENCIAL'}
            </Text>
          </Flex>

          <SimpleGrid minChildWidth="120px" spacing="4" w="100%">
            {patient?.statusConsulta === 0 && patient?.consultation === 1 ? (
              // <a
              //   href={patient?.urlVideo}
              //   target="_blank"
              //   rel="noopener noreferrer"
              // >
              <Button
                type="button"
                colorScheme="brand-green"
                h="45px"
                borderRadius="28px"
                color="white"
                fontSize="xs"
                fontWeight="medium"
                textTransform="uppercase"
                w="50%"
                marginTop="20px"
                marginLeft="25%"
                onClick={OpenConsult}
              >
                Entrar
              </Button>
            ) : (
              <Button
                onClick={onClose}
                type="button"
                colorScheme="brand-green"
                h="45px"
                borderRadius="28px"
                color="white"
                fontSize="xs"
                fontWeight="medium"
                textTransform="uppercase"
                w="50%"
                marginLeft="25%"
              >
                Ok
              </Button>
            )}
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
