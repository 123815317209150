import { Box, useRadio, useRadioGroup, Text, Flex } from '@chakra-ui/react';
import { Card } from 'antd';
import { useEffect, useState } from 'react';
import { HistoricModal } from '../TableSection/HistoricModal/HistoricModal';
import { getPatientPrescription } from '../../hooks';

function CardItem(props: any) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />

      <Box
        {...checkbox}
        cursor="pointer"
        fontSize="md"
        fontWeight="500"
        _checked={{
          bg: 'transparent',
          color: '#60C2B0',
        }}
        fontFamily="Rubik"
        px={5}
        py={3}
        borderBottom="1px solid #E4E4E4"
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default function DadosCard({ dados }: any) {
  const [textCard, setTextCard] = useState<any[]>([]);
  const [option, setOption] = useState('HISTÓRICO FAMILIAR');
  const [prescription, setPrescription] = useState<any>([]);
  const options = ['HISTÓRICO FAMILIAR', 'CIRURGIAS', 'DIAGNÓSTICOS'];

  async function getDataPrescription() {
    if (dados.anamnese) {
      const { pacienteId: idPaciente, consultaId: idConsulta } = dados.anamnese;

      const resp = await getPatientPrescription(idPaciente, idConsulta);
      if (resp) {
        setPrescription(resp);
      }
    }
  }

  const SetOptionValue = (v: any) => {
    setOption(v);

    setTextCard([]);

    if (v === 'HISTÓRICO FAMILIAR' && dados?.anamnese?.antecedentesFamiliares) {
      const cirurg = dados?.anamnese?.antecedentesFamiliares?.cirurgias.map(
        (a: any) => a?.descricao,
      );

      const diag = dados?.anamnese?.antecedentesFamiliares?.diagnosticos.map(
        (a: any) => a?.descricao,
      );
      setTextCard([...cirurg, ...diag]);
    }
    if (v === 'CIRURGIAS' && dados?.anamnese?.antecedentesPessoais?.cirurgias) {
      const cirurg = dados?.anamnese?.antecedentesPessoais?.cirurgias.map(
        (a: any) => a?.descricao,
      );
      setTextCard(cirurg);
    }
    if (
      v === 'DIAGNÓSTICOS' &&
      dados?.anamnese?.antecedentesPessoais?.diagnosticos
    ) {
      const diag = dados?.anamnese?.antecedentesPessoais?.diagnosticos.map(
        (a: any) => a?.descricao,
      );
      setTextCard(diag);
    }
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'framework',
    defaultValue: 'QUEIXA / EVOLUÇÃO',
    onChange: SetOptionValue,
  });

  useEffect(() => {
    setPrescription([]);
    if (dados) {
      setTextCard([]);
      getDataPrescription();
      SetOptionValue(option);
    }
  }, [dados]);

  useEffect(() => {}, [dados]);

  return (
    <Flex>
      <Box>
        {options.map(value => {
          const radio = getRadioProps({ value });
          return (
            <CardItem key={value} {...radio} defaultValue="QUEIXA / EVOLUÇÃO">
              {value}
            </CardItem>
          );
        })}
      </Box>
      <Flex flex="1">
        <Box backgroundColor="#fff" width="100vw" height="100%">
          {option === 'PRESCRIÇÃO' && prescription?.result ? (
            <Card
              style={{
                height: '100%',
                borderRadius: '10px',
                backgroundColor: '#fff',
                overflowY: 'hidden',
              }}
            >
              <HistoricModal prescription={prescription} />
            </Card>
          ) : (
            <Card
              style={{
                height: '100%',
                borderRadius: '10px',
                backgroundColor: '#fff',
                overflowY: 'auto',
              }}
            >
              {textCard.length
                ? textCard?.map((p: any) => <Text>{`- ${p}`}</Text>)
                : 'NÃO HÁ DADOS'}
            </Card>
          )}
        </Box>
      </Flex>
    </Flex>
  );
}
