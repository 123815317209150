import { useMutation, useQuery } from 'react-query';

import { api } from '@/lib';
import { useProfessionalStore, useUserStore } from '@/stores';
import { format, parseISO } from 'date-fns';
import { formatDate } from '@/utils';

async function getConsultasList(id: any): Promise<any[]> {
  try {
    const response = await api.get('/consulta/obter-todos-sem-filtro', {
      params: {
        idPaciente: id,
        // ano: 2024,
      },
    });

    const formatData = response.data.data.sort((a: any, b: any) => {
      if (
        Date.parse(a.horasAgenda.agenda.data) <
        Date.parse(b.horasAgenda.agenda.data)
      ) {
        return -1;
      }
      if (
        Date.parse(b.horasAgenda.agenda.data) <
        Date.parse(a.horasAgenda.agenda.data)
      ) {
        return 1;
      }
      return 0;
    });

    return formatData;
  } catch {
    return [];
  }
}

export function useGetConsultas() {
  const mutation = useMutation((idPacient: String) =>
    getConsultasList(idPacient),
  );

  return mutation;
}

export async function getAnamneseMevo(idConsulta: string, idPaciente: string) {
  const { data } = await api.get('/anamnese/obter-consulta', {
    params: { idConsulta },
  });

  const { data: res } = await api.get('/mevo/get-Mevo', {
    params: { idPaciente, idConsulta },
  });

  return { anamnese: data.data, dadosMevo: res.data[0] };
}
