import { Box, useRadio, useRadioGroup, Text, Flex } from '@chakra-ui/react';
import { Card } from 'antd';
import { useEffect, useState } from 'react';
import { HistoricModal } from '../TableSection/HistoricModal/HistoricModal';
import { getPatientPrescription } from '../../hooks';

function CardItem(props: any) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />

      <Box
        {...checkbox}
        cursor="pointer"
        fontSize="md"
        fontWeight="500"
        _checked={{
          bg: 'transparent',
          color: '#60C2B0',
        }}
        fontFamily="Rubik"
        px={5}
        py={3}
        borderBottom="1px solid #E4E4E4"
        whiteSpace="nowrap"
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default function RadioCard({ dados }: any) {
  const [textCard, setTextCard] = useState<any[]>([]);
  const [option, setOption] = useState('PRESCRIÇÃO');
  const [prescription, setPrescription] = useState<any>([]);
  const options = [
    'PRESCRIÇÃO',
    'QUEIXA / EVOLUÇÃO',
    'ALERGIAS',
    'CID',
    'ORIENTAÇÕES / CONDUTA',
  ];

  async function getDataPrescription() {
    if (dados.anamnese) {
      const { pacienteId: idPaciente, consultaId: idConsulta } = dados.anamnese;

      const resp = await getPatientPrescription(idPaciente, idConsulta);
      if (resp) {
        setPrescription(resp);
      }
    }
  }

  const SetOptionValue = (v: any) => {
    setOption(v);

    setTextCard([]);

    if (v === 'PRESCRIÇÃO') {
      setTextCard([]);
    }
    if (v === 'QUEIXA / EVOLUÇÃO' && dados?.anamnese?.duracao) {
      setTextCard([dados?.anamnese?.duracao] || '');
    }
    if (v === 'ORIENTAÇÕES / CONDUTA' && dados?.anamnese?.conduta) {
      setTextCard([dados?.anamnese?.conduta] || '');
    }
    if (v === 'CID' && dados?.anamnese?.cid) {
      const c = dados?.anamnese?.cid.map((cid: any) => cid?.descricao);
      setTextCard(c);
    }
    if (v === 'ALERGIAS' && dados?.anamnese?.alergica) {
      const aler = dados?.anamnese?.alergica.map((a: any) => a?.descricao);
      setTextCard(aler);
    }
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'framework',
    defaultValue: 'PRESCRIÇÃO',
    onChange: SetOptionValue,
  });

  useEffect(() => {
    setPrescription([]);
    if (dados) {
      setTextCard([]);
      getDataPrescription();
      SetOptionValue(option);
    }
  }, [dados]);

  return (
    <Flex>
      <Box>
        {options.map(value => {
          const radio = getRadioProps({ value });
          return (
            <CardItem
              key={value}
              {...radio}
              defaultValue="PRESCRIÇÃO"
              color="red"
            >
              {value}
            </CardItem>
          );
        })}
      </Box>
      <Flex flex="1">
        <Box backgroundColor="#fff" width="100%" height="100%">
          {option === 'PRESCRIÇÃO' && prescription?.result ? (
            <Card
              style={{
                // maxHeight: '60rem',
                height: '100%',
                borderRadius: '10px',
                backgroundColor: '#fff',
                overflowY: 'auto',
              }}
            >
              <HistoricModal prescription={prescription} />
            </Card>
          ) : (
            <Card
              style={{
                height: '100%',
                borderRadius: '10px',
                backgroundColor: '#fff',
                overflowY: 'auto',
              }}
            >
              {textCard.length
                ? textCard?.map((p: any) => <Text>{`- ${p}`}</Text>)
                : 'NÃO HÁ DADOS'}
            </Card>
          )}
        </Box>
      </Flex>
    </Flex>
  );
}
