import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  addBatchSchema,
  useAddBatchMutation,
  useScheduleDateStore,
} from '@/features/schedule';

import {
  MaskedInput,
  SelectInput,
  TextAreaInput,
  TextInput,
} from '@/components';

import confirmationImg from '@/assets/register_confirmed.svg';
import { api } from '@/lib';
import {
  useInstanciaStore,
  useProfessionalStore,
  useUnidadeStore,
  useUserStore,
} from '@/stores';
import moment from 'moment';
import { PickerInput } from '@/components/Form/PickerInput';
import { IoCalendarClearOutline } from 'react-icons/io5';
import { DatePicker, Input } from 'antd';
import { useScheduleIntervalQuery } from '@/features/schedule/hooks/useScheduleIntervalQuery';
import axios from 'axios';
import { useScheduleQuery } from '@/features/schedule/hooks/useScheduleQuery';
import {
  finalTimeSuggestion,
  initialTimeSuggestion,
} from '@/features/schedule/utils/timeSugestion';
import { useUnidadesIdQuery } from '@/hooks';
import dayjs from 'dayjs';

type AddBatchFormFields = {
  dataInicio: string;
  quantidade: number;
  horarioInicio: string;
  horarioFim: string;
  observacao: string;
};

interface AddBatchFormProps {
  onCancel: () => void;
  pageStart?: any;
}

export function AddBatchForm({ onCancel, pageStart }: AddBatchFormProps) {
  const user = useUserStore(state => state.user);
  const pro = useProfessionalStore(state => state.professional);
  let id = user?.id;

  if (user && user.type !== 'medico') {
    id = pro?.idMedico;
  }
  const [isLoading, setIsLoading] = useState(false);
  const { refetch } = useScheduleIntervalQuery();
  const { refetch: refresh } = useScheduleQuery();
  const instances = useInstanciaStore(state => state.instancia);
  const [instanciaId, setInstanciaId] = useState(instances?.id);
  const { data: unidades = [] } = useUnidadesIdQuery(instanciaId || '');
  const unidadeSet = useUnidadeStore(state => state.unidade);
  const [unidadeId, setUnidadeId] = useState(unidadeSet?.id);

  const [wasFormSubmittedWithSuccess, setWasFormSubmittedWithSuccess] =
    useState(false);
  const referenceDate = useScheduleDateStore(state => state.referenceDate);
  const [data, setData] =
    dayjs() >= dayjs(referenceDate)
      ? useState(dayjs())
      : useState(dayjs(referenceDate));
  const [horaInicio, setHoraInicio] = useState(initialTimeSuggestion);
  const [horaFim, setHoraFim] = useState(finalTimeSuggestion);

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<AddBatchFormFields>({
    defaultValues: {
      dataInicio: '',
      quantidade: 1,
      horarioInicio: '',
      horarioFim: '',
      observacao: '',
    },
    resolver: yupResolver(addBatchSchema),
  });

  async function onSubmit(values: AddBatchFormFields) {
    const [scheduleHour, scheduleMinute] = horaInicio.split(':');
    const [fimHour, fimMinute] = horaFim.split(':');
    if (
      scheduleHour > fimHour ||
      (scheduleHour === fimHour && scheduleMinute > fimMinute)
    ) {
      toast({
        title: 'Horário final menor que horário incial',
        status: 'error',
      });

      return;
    }

    const newBatch = {
      idUsuario: id,
      status: 0,
      data: data.toISOString(),
      horarioInicio: horaInicio,
      horarioFim: horaFim,
      quantidade: values.quantidade,
      observacao: values.observacao,
      idUnidade: unidadeSet?.id,
    };

    try {
      setIsLoading(true);
      await api.post('/agenda/criacaoemlote', newBatch);
      refetch();
      refresh();
      setWasFormSubmittedWithSuccess(true);
    } catch (error) {
      let message;
      if (axios.isAxiosError(error)) {
        const { response } = error;
        message = response?.data?.errors[0] || response?.data || undefined;
      }

      toast({
        title: message || 'Ocorreu um erro',
        status: 'error',
      });
      setWasFormSubmittedWithSuccess(false);
    } finally {
      setIsLoading(false);
    }
  }

  return wasFormSubmittedWithSuccess ? (
    <Flex w="100%" direction="column" align="center">
      <Heading
        mt="6"
        color="brand-dark.300"
        fontSize="2xl"
        fontWeight="medium"
        textAlign="center"
      >
        O lote foi criado!
      </Heading>

      <Image
        mt="8"
        mb="4"
        src={confirmationImg}
        alt="Mulher confirmando o sucesso de uma operação em um celular grande"
      />

      <Text
        color="brand-dark.200"
        fontSize="sm"
        fontWeight="normal"
        textAlign="center"
      >
        Você pode editar as configurações na aba de escalas.
      </Text>

      <Button
        type="button"
        onClick={onCancel}
        colorScheme="brand-green"
        mt="10"
        h="45px"
        borderRadius="28px"
        color="white"
        fontSize="xs"
        fontWeight="medium"
        textTransform="uppercase"
      >
        Ir para escalas
      </Button>
    </Flex>
  ) : (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w="100%"
      textAlign="center"
    >
      <Heading mt="6" mb="4" color="#ee7e44" fontSize="xl" fontWeight="medium">
        Lote da Escala
      </Heading>

      <VStack spacing="4">
        <SelectInput
          label="Unidades de Saúde"
          options={unidades}
          placeholder="Selecione unidade de saúde"
          disabled
          value={unidadeId}
        />
        <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
          {/* <PickerInput
            name="dataInicio"
            label="Data - Início"
            control={control}
            mask="date"
            useMaskedValue
            error={errors.dataInicio}
            placeholder="Ex: 01/01/1970"
            icon={IoCalendarClearOutline}
          /> */}

          <FormControl>
            <FormLabel htmlFor="data" width="100%">
              Data
            </FormLabel>
            <DatePicker
              getPopupContainer={triggerNode => {
                return triggerNode?.parentNode as HTMLElement;
              }}
              onChange={(selectedDay: any) => {
                if (selectedDay) {
                  setData(selectedDay);
                }
              }}
              disabledDate={(current: any) => {
                return moment().add(-1, 'days') >= current;
              }}
              size="large"
              style={{ width: '100%' }}
              format="DD/MM/YYYY"
              placeholder="Selecionar data"
              defaultValue={data}
            />
          </FormControl>

          <TextInput
            label="Quantidade"
            error={errors.quantidade}
            {...register('quantidade')}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
          {/* <MaskedInput
            name="horarioInicio"
            label="Horário - Início"
            control={control}
            mask="time"
            error={errors.horarioInicio}
            placeholder="Ex: 14:50"
          />

          <MaskedInput
            name="horarioFim"
            label="Horário - Fim"
            control={control}
            mask="time"
            error={errors.horarioFim}
            placeholder="Ex: 15:50"
          /> */}
          <FormControl>
            <FormLabel htmlFor="data" width="100%">
              Horário - Início
            </FormLabel>
            <Input
              type="time"
              id="horarioInicio"
              name="horarioInicio"
              value={horaInicio}
              style={{
                width: '100%',
              }}
              placeholder="Selecionar hora"
              onChange={(e: any) => {
                if (e) {
                  setHoraInicio(e.target.value);
                }
              }}
              required
            />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="data" width="100%">
              Horário - Fim
            </FormLabel>
            <Input
              type="time"
              id="horarioFim"
              name="horarioFim"
              value={horaFim}
              style={{ width: '100%' }}
              placeholder="Selecionar hora"
              onChange={(e: any) => {
                if (e) {
                  setHoraFim(e.target.value);
                }
              }}
              required
            />
          </FormControl>
        </SimpleGrid>

        <TextAreaInput
          placeholder="Ex: Retorno ao paciente"
          label="Observação"
          {...register('observacao')}
        />

        <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
          <Button
            disabled={isLoading}
            type="button"
            onClick={onCancel}
            variant="outline"
            colorScheme="red"
            h="45px"
            borderRadius="28px"
            color="red"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
          >
            Cancelar
          </Button>

          <Button
            isLoading={isLoading}
            type="submit"
            colorScheme="brand-green"
            h="45px"
            borderRadius="28px"
            color="white"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
          >
            Incluir
          </Button>
        </SimpleGrid>
      </VStack>
    </Box>
  );
}
