import { useGetConsultas } from '@/features/historic/hooks/getConsultas';
import { MONTHS } from '@/features/schedule';
import { useUserStore } from '@/stores';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Avatar,
  Text,
  VStack,
  Button,
  Grid,
  GridItem,
  Flex,
  Box,
  CircularProgress,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import HistoryCard from './HistoryCard';

const avatar =
  'https://avatars.dicebear.com/api/micah/usr.svg?backgroundColor=black';

export default function DrawerInfos({
  isOpen,
  onOpen,
  onClose,
  paciente,
}: any) {
  const navigate = useNavigate();
  const [consultas, setConsultas] = useState<any[]>();
  const [selectConsulta, setSelectConsul] = useState<any>();
  const [CIDS, setCIDS] = useState<any[]>([]);
  const [loadCid, setLoad] = useState(false);
  const [consultasForm, setConsultasForm] = useState<any[]>();
  const user = useUserStore(state => state.user);
  const { mutate: getConsults, isLoading } = useGetConsultas();

  const getConsultas = async () => {
    getConsults(paciente?.idPaciente, {
      onSuccess: response => {
        setConsultas(response);
      },
    });
  };

  useEffect(() => {
    getConsultas();
  }, [paciente]);

  const SetConsultaSelect = (pac: any) => {
    setSelectConsul(pac);
  };

  const getDateMonth = (num: number) => {
    const name = MONTHS.filter(m => m.numeric === num)[0];

    return name.numeric;
  };

  const getCategories = () => {
    if (consultas) {
      const c = consultas?.reduce((acc, item, i) => {
        return {
          ...acc,
          [getDateMonth(new Date(item.dataPedido).getMonth())]: [
            ...(acc[getDateMonth(new Date(item.dataPedido).getMonth())] ?? []),
            item,
          ],
        };
      }, {});
      setConsultasForm(c);
    }
  };

  const getCIDS = () => {
    setLoad(true);
    const dados: any[] = [];
    const c = paciente?.anamineses?.map((a: any) => {
      const ana = a.CID.map((aa: any) => {
        if (aa.codCid) dados.push(aa.codCid);
        return aa.codCid;
      });
      return ana;
    });

    const novaArr = dados.filter((is, i) => dados.indexOf(is) === i);
    setCIDS(novaArr);
    setLoad(false);
  };

  useEffect(() => {
    getCategories();
    getCIDS();
  }, [consultas]);

  useEffect(() => {
    if (selectConsulta) {
      navigate('/app/historic', {
        state: { paciente, consulta: selectConsulta },
      });
    }
  }, [selectConsulta]);

  useEffect(() => {
    setCIDS([]);
  }, [isOpen]);

  return (
    <Drawer onClose={onClose} isOpen={isOpen} size="xl">
      <DrawerOverlay />
      <DrawerContent style={{ paddingLeft: 20, color: 'brand-dark.300' }}>
        <DrawerCloseButton style={{ left: 10 }} />
        <DrawerHeader>
          <Grid width="100%" templateColumns="repeat(12, 1fr)">
            <GridItem display="flex" colSpan={6}>
              <Button pr="12" h="52px" borderRadius="14" bg="white">
                <Avatar
                  size="md"
                  src={paciente?.urlFoto || avatar}
                  name={paciente?.nomePaciente}
                />

                <VStack spacing="1" ml="4" align="flex-start">
                  <Text
                    color="brand-dark.200"
                    fontSize="xs"
                    fontWeight="normal"
                    fontFamily="Rubik"
                  >
                    PACIENTE
                  </Text>
                  <Text
                    color="brand-dark.500"
                    fontSize="lg"
                    fontWeight="large"
                    fontFamily="Rubik"
                  >
                    {paciente?.nomePaciente}
                  </Text>
                </VStack>
              </Button>
            </GridItem>

            <GridItem display="flex" justifyContent="flex-end" colSpan={6}>
              <Button
                fontSize="sm"
                h="52px"
                borderRadius="6"
                bg="#60C2B0"
                color="white"
                onClick={() =>
                  navigate('/app/historic', {
                    state: { paciente },
                  })
                }
              >
                ABRIR
              </Button>
            </GridItem>
          </Grid>
        </DrawerHeader>
        <DrawerBody ml="15" mt="15">
          <Text
            fontSize="lg"
            w="100%"
            color="brand-dark.300"
            fontFamily="Rubik"
            paddingBottom="19"
          >
            CID
          </Text>

          {loadCid ? (
            <CircularProgress isIndeterminate color="brand-dark.100" />
          ) : (
            <Flex>
              {CIDS && CIDS?.length > 0 ? (
                CIDS?.map((c: any) => (
                  <Box
                    fontSize="md"
                    borderRadius="6"
                    border="1px solid #A1A1A1"
                    padding="2"
                    margin="2"
                    width="15%"
                    alignContent="center"
                    textAlign="center"
                    fontFamily="Rubik"
                    color="#A1A1A1"
                  >
                    {c}
                  </Box>
                ))
              ) : (
                <Box
                  fontSize="sm"
                  borderRadius="6"
                  border="1px solid #A1A1A1"
                  padding="2"
                  margin="2"
                  width="auto"
                  alignContent="center"
                  textAlign="center"
                  fontFamily="Rubik"
                  color="#A1A1A1"
                >
                  NENHUM CID REGISTRADO
                </Box>
              )}
            </Flex>
          )}

          <Box display={{ lg: 'flex' }}>
            <HistoryCard
              paciente={paciente}
              setSelectConsul={SetConsultaSelect}
            />
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
