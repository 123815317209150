import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Text,
  Grid,
  GridItem,
  Icon,
  Box,
  Button,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';

import { useProfessionalsQuery } from '@/hooks';
import { useProfessionalStore, useUserStore } from '@/stores';

const styles = {
  root: {
    alignItems: 'center',
    background: 'transparent',
    borderRadius: '10px',
    cursor: 'pointer',
    display: 'flex',
    height: '52px',
    marginRight: '50px',
    padding: '0px 10px',
    width: 'fit-content',
  },
};

export function ProfessionalSelect() {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  const proRef = useRef(null);
  const [name, setName] = useState<string>('');
  const [professionals, setProfessionals] = useState<any[]>([]);
  const [isSelect, setIsSelect] = useState<boolean>();

  const setProfessional = useProfessionalStore(state => state.setProfessional);
  const { data } = useProfessionalsQuery();
  const user = useUserStore(state => state.user);
  const [isProfessionalSelect, setIsProfessionalSelect] = useState<boolean>();

  useEffect(() => {
    if (user && user.type !== 'medico' && user.type !== 'paciente') {
      setIsProfessionalSelect(true);
    }
  }, [user]);

  const handleChangeProfessional = useCallback(
    (pro: any) => {
      setName(pro.label);
      setProfessional({
        id: pro.value,
        name: pro.label,
        idMedico: pro.idMedico,
        idUsuario: pro.idUsuario,
      });
      setIsSelect(false);
    },
    [setProfessional],
  );

  const handleSelectOpen = useCallback(() => {
    setIsSelect(!isSelect);
  }, [isSelect]);

  useEffect(() => {
    if (data && data.length > 0) {
      const pro = data[0];
      setName(pro.label);
      setProfessional({
        id: pro.value,
        name: pro.label,
        idMedico: pro.idMedico,
        idUsuario: pro.idUsuario,
      });
      setProfessionals(
        data.filter((d: { primeiroAcesso: boolean }) => !d.primeiroAcesso),
      );
    } else {
      setName('');
      setProfessional(null);
      setProfessionals([]);
    }
  }, [data, setProfessional]);

  useEffect(() => {
    const pageClickEvent = (e: any) => {
      /* @ts-ignore */
      if (proRef.current !== null && !proRef?.current?.contains(e.target)) {
        setIsSelect(!isSelect);
      }
    };
    if (isSelect) {
      window.addEventListener('click', pageClickEvent);
    }
    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isSelect]);

  if (user?.type === 'paciente' || user?.type === 'medico') return null;

  return (
    <div ref={proRef} style={styles.root}>
      {isProfessionalSelect ? (
        <Flex position="relative">
          <Grid
            width={{ lg: '10rem', sm: '1rem', md: '5rem' }}
            templateColumns="repeat(4, 1fr)"
            onMouseDown={handleSelectOpen}
            alignItems="center"
          >
            {!isMobile && (
              <GridItem
                colSpan={3}
                display="flex"
                alignItems="center"
                flexDirection="column"
                position="relative"
              >
                <Text
                  fontFamily="Montserrat"
                  fontSize={{ lg: '14', md: '8' }}
                  fontStyle="normal"
                  fontWeight="600"
                  marginRight="1.5"
                >
                  {name || 'Profissional de saúde:'}
                </Text>
                {/* <Text
                fontSize={{ lg: '14', md: '6' }}
                fontFamily="Montserrat"
                fontStyle="normal"
                fontWeight="500"
              >

                {/* {name}
              </Text> */}
              </GridItem>
            )}
            <GridItem colSpan={1} display="flex">
              <Icon
                as={FiChevronDown}
                color="brand-dark.600"
                fontSize="20px"
                fontWeight="400"
              />
            </GridItem>
          </Grid>
          {isSelect && (
            <Box
              bg="white"
              borderRadius="10px"
              boxShadow="0.8px 0.8px 7px -2px rgba(5, 6, 27, 0.25)"
              marginLeft="-1"
              position="absolute"
              // padding="10px"
              top="39px"
              zIndex="3"
              width="auto"
            >
              {professionals.map((pro, index) => {
                return (
                  <Button
                    key={pro.idUsuario}
                    bg="white"
                    width="100%"
                    color={
                      pro.primeiroAcesso ? 'brand-dark.100' : 'brand-dark.300'
                    }
                    fontFamily="Rubik"
                    fontWeight="sm"
                    borderBottom={
                      index === professionals.length - 1
                        ? 'none'
                        : '1px solid #C2C2C2'
                    }
                    cursor={pro.primeiroAcesso ? 'not-allowed' : 'pointer'}
                    borderRadius="none"
                    onClick={() => {
                      if (pro.primeiroAcesso) return;
                      handleChangeProfessional(pro);
                    }}
                  >
                    <Text textAlign="left" width="100%">
                      {pro.label}
                    </Text>
                  </Button>
                );
              })}
            </Box>
          )}
        </Flex>
      ) : null}
    </div>
  );
}
