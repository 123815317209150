import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { api } from '@/lib';
import { useUserStore } from '@/stores';
import { InstanceTypes, UserTypes } from '@/types';
import { useInstanciaStore } from '@/stores/instancias';
import { string } from 'yup';

type LoginPayload = {
  userName: string;
  senha: string;
};
type changePersonaPayload = {
  usuarioId: string;
  personaUsuarioId: string;
  instanciaId: string;
};

type LoginAPIResponse = {
  data: {
    accessToken: string;
    userToken: {
      id: string;
      tipo: number;
      email?: string;
      gestorMasterId?: string;
      questionario?: boolean;
      nome: string;
      instanciasId: string[];
      unidadesId: string[];
      isAdmin: boolean;
      isMaster: boolean;
      profissionalId: string | null;
      pacienteId: string | null;
      codigoVinculo: string;
    };
  };
};

async function changePersonaRequest(
  params: changePersonaPayload,
): Promise<LoginAPIResponse> {
  const response = await api.post('/usuario/Trocar-Persona', {}, { params });
  return response.data;
}

async function loginRequest(data: LoginPayload): Promise<LoginAPIResponse> {
  const response = await api.post('/usuario/AutenticarNovo', data);
  return response.data;
}

async function getCodVinculo(id: string): Promise<string> {
  const response = await api.get(`/usuario/profissionalsaudebyid?id=${id}`);
  return response.data.data.codigoVinculo;
}

export async function verifyLogin(login: string): Promise<any> {
  // 0- Não cadastrado
  // 1- Login
  // 2- Código
  return api
    .get(`/usuario/TipoLoginRetorno?login=${login}`)
    .then(r => {
      return r.data;
    })
    .catch(e => {
      if (axios.isAxiosError(e)) {
        const { response: res } = e;
        return res;
      }
      return e;
    });
}

export async function SendEmailCode(email: string): Promise<string> {
  const response = await api.post(`/usuario/EnviarEmailCodigo?email=${email}`);
  return response.data.success;
}

export async function ValidateCodeEmail(
  email: string,
  code: string,
): Promise<any> {
  return api
    .post(`/usuario/ValidarCodigoEmail?email=${email}&codigo=${code}`)
    .then(r => {
      return r;
    })
    .catch(e => {
      if (axios.isAxiosError(e)) {
        const { response: res } = e;
        return res;
      }
      return e;
    });
}

export async function FirstAccess(params: any): Promise<any> {
  return api
    .get(`/usuario/PrimeiroAcesso`, {
      params,
    })
    .then(r => {
      return r;
    })
    .catch(e => {
      if (axios.isAxiosError(e)) {
        const { response: res } = e;
        return res;
      }
      return e;
    });
}

export function useLoginMutation() {
  const setUser = useUserStore(state => state.setUser);

  const navigate = useNavigate();

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const mutation = useMutation((data: LoginPayload) => loginRequest(data), {
    onSuccess: async response => {
      const { data } = response;

      localStorage.setItem('@dmh:token', data.accessToken);

      api.interceptors.request.use(config => ({
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${data.accessToken || ''}`,
        },
      }));

      /**
       * Tipos de usuário
       * admin = 0,
       * master = 5,
       * secretario = 2,
       * medico = 3,
       * paciente = 4,
       * gestorunidade = 6
       * gestorInstancia = 7
       * Por padrão, o tipo será paciente
       */
      let type: UserTypes = 'paciente';
      let descricao = 'Paciente';
      let typeInstance: InstanceTypes = 'id-paciente';
      let isAdmin: boolean = false;
      let isMaster: boolean = false;

      switch (data.userToken.tipo) {
        case 0:
          type = 'admin';
          descricao = 'Gestor Pleno';
          typeInstance = 'id-gestor-master';
          isAdmin = true;
          isMaster = true;
          break;
        // case 1:
        //   type = 'master';
        //   typeInstance = 'id-gestor-master';
        //   break;
        case 2:
          type = 'assistente';
          descricao = 'Assistente';
          typeInstance = 'id-assistente';
          break;
        case 3:
          type = 'medico';
          descricao = 'Profissional de Saúde';
          typeInstance = 'id-profissional';
          break;
        case 4:
          type = 'paciente';
          descricao = 'Paciente';
          typeInstance = 'id-paciente';
          break;
        case 5:
          type = 'master';
          descricao = 'Gestor Instância_TI';
          typeInstance = 'id-gestor-master';
          isMaster = true;
          break;
        case 6:
          type = 'gestor';
          descricao = 'Gestor de unidade';
          typeInstance = 'gestor';
          break;
        case 7:
          type = 'master';
          descricao = 'Gestor Instância_TI';
          typeInstance = 'id-gestor-master';
          isMaster = true;
          break;
        default:
          type = 'paciente';
          descricao = 'Paciente';
          typeInstance = 'id-paciente';
          break;
      }

      let codigo;
      if (type === 'medico') {
        codigo = await getCodVinculo(data.userToken.id);
      }

      const user = {
        id: data.userToken.id,
        gestorMasterId: data.userToken.gestorMasterId || '',
        email: data.userToken.email || '',
        questionario: data.userToken.questionario || false,
        name: data.userToken.nome,
        profissionalId: data.userToken.profissionalId || null,
        codigoVinculo: codigo || '',
        pacienteId: data.userToken.pacienteId || null,
        type,
        descricao,
        avatar:
          'https://avatars.dicebear.com/api/micah/user.svg?backgroundColor=black', // Mock
        instanciasId: data.userToken.instanciasId,
        unidadesId: data.userToken.unidadesId,
        isAdmin,
        isMaster,
      };

      setUser(user);

      toast({
        title: 'Seja bem vindo!',
        status: 'success',
      });

      navigate('/app/start');
    },
    onError: error => {
      if (axios.isAxiosError(error)) {
        const { response } = error;

        if (response?.status === 400) {
          toast({
            title: 'E-mail e/ou senha inválido(s)',
            status: 'error',
          });
        } else {
          toast({
            title: 'Não foi possível logar no momento',
            status: 'error',
          });
        }
      } else {
        toast({
          title: 'Servidor não disponível no momento',
          status: 'error',
        });
      }
    },
  });

  return mutation;
}

export function usechangePersonaMutation() {
  const setUser = useUserStore(state => state.setUser);

  const navigate = useNavigate();

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const mutation = useMutation(
    (data: changePersonaPayload) => changePersonaRequest(data),
    {
      onSuccess: async response => {
        const { data } = response;
        /**
         * Tipos de usuário
         * admin = 0,
         * master = 5,
         * secretario = 2,
         * medico = 3,
         * paciente = 4,
         * gestor = 6
         * Por padrão, o tipo será paciente
         */
        let type: UserTypes = 'paciente';
        let descricao = 'Paciente';
        let typeInstance: InstanceTypes = 'id-paciente';
        let isAdmin: boolean = false;
        let isMaster: boolean = false;

        switch (data.userToken.tipo) {
          case 0:
            type = 'admin';
            descricao = 'Gestor Pleno';
            typeInstance = 'id-gestor-master';
            isAdmin = true;
            isMaster = true;
            break;
          // case 1:
          //   type = 'master';
          //   typeInstance = 'id-gestor-master';
          //   break;
          case 2:
            type = 'assistente';
            descricao = 'Assistente';
            typeInstance = 'id-assistente';
            break;
          case 3:
            type = 'medico';
            descricao = 'Profissional de Saúde';
            typeInstance = 'id-profissional';
            break;
          case 4:
            type = 'paciente';
            descricao = 'Paciente';
            typeInstance = 'id-paciente';
            break;
          case 5:
            type = 'master';
            descricao = 'Gestor Instância_TI';
            typeInstance = 'id-gestor-master';
            isMaster = true;
            break;
          case 6:
            type = 'gestor';
            descricao = 'Gestor de unidade';
            typeInstance = 'gestor';
            break;
          default:
            type = 'paciente';
            descricao = 'Paciente';
            typeInstance = 'id-paciente';
            break;
        }

        let codigo;
        if (type === 'medico') {
          codigo = await getCodVinculo(data.userToken.id);
        }

        const user = {
          id: data.userToken.id,
          gestorMasterId: data.userToken.gestorMasterId || '',
          email: data.userToken.email || '',
          questionario: data.userToken.questionario || false,
          name: data.userToken.nome,
          profissionalId: data.userToken.profissionalId || null,
          codigoVinculo: codigo || '',
          pacienteId: data.userToken.pacienteId || null,
          type,
          descricao,
          avatar:
            'https://avatars.dicebear.com/api/micah/user.svg?backgroundColor=black', // Mock
          instanciasId: data.userToken.instanciasId,
          unidadesId: data.userToken.unidadesId,
          isAdmin,
          isMaster,
        };

        setUser(user);

        localStorage.setItem('@dmh:token', data.accessToken);

        api.interceptors.request.use(config => ({
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${data.accessToken || ''}`,
          },
        }));

        toast({
          title: 'Seja bem vindo!',
          status: 'success',
        });

        navigate('/app/start');
      },
      onError: error => {
        if (axios.isAxiosError(error)) {
          const { response } = error;

          if (response?.status === 400) {
            toast({
              title: 'E-mail e/ou senha inválido(s)',
              status: 'error',
            });
          } else {
            toast({
              title: 'Não foi possível logar no momento',
              status: 'error',
            });
          }
        } else {
          toast({
            title: 'Servidor não disponível no momento',
            status: 'error',
          });
        }
      },
    },
  );

  return mutation;
}
