import HistoryCard from '@/features/historic/components/Drawer/HistoryCard';
import { useLocation } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Grid,
  GridItem,
  Icon,
  Text,
  VStack,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { IoAddOutline, IoAdd } from 'react-icons/io5';
import { useEffect, useState, useRef, useMemo } from 'react';
import { isMobile } from '@/utils';
import { GenericModal } from '@/features/registers';
import AnamneseCard from '../components/Drawer/AnamneseCard';
import { DrawerIaChat } from '../../../components/IaChat/DrawerIaChat';
import { GuidanceModal } from '../../../components/IaChat/components/GuidanceModal';
import MonitoringCard from '../components/Drawer/Monitoring';
import ExamsCard from '../components/Drawer/Exams';
import { ViewPatientConsultInfo } from '../components/ViewPatientConsultInfo';

const avatar =
  'https://avatars.dicebear.com/api/micah/usr.svg?backgroundColor=black';

export function HistoricPatientPages({ paciente }: any) {
  const loc = useLocation();
  const [location, setLocation] = useState<any>(loc.state);
  const [selectConsulta, setSelectConsul] = useState<any>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const openDrawerBtn = useRef<HTMLButtonElement>(null);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [viewPatient, setViewPatient] = useState<boolean>(false);

  function onCloseCancel() {
    setModalOpen(false);
  }

  const [chatCallbackMessage, setChatCallbackMessage] = useState<string>();

  const tabsTitles = useMemo(
    () => ['HISTÓRICO', 'MONITORAMENTO', 'EXAMES'],
    [],
  );

  const SetConsultaSelect = (pac: any) => {
    setSelectConsul(pac);
  };

  useEffect(() => {
    setLocation(loc.state);
    if (location.consulta) {
      setSelectConsul(location.consulta);
    }
  }, [loc]);

  function ConsultationInfo() {
    return (
      <Grid width="100%" templateColumns="repeat(12, 1fr)">
        <GridItem display="flex" colSpan={12}>
          <Avatar
            size="lg"
            src={location.paciente?.urlFoto || avatar}
            name={location.paciente?.nomePaciente}
          />

          <VStack spacing="1" ml="4" align="flex-start">
            <Text
              color="brand-dark.200"
              fontSize="xs"
              fontWeight="normal"
              fontFamily="Rubik"
            >
              PACIENTE
            </Text>
            <Text
              color="brand-dark.500"
              fontSize="lg"
              fontWeight="large"
              fontFamily="Rubik"
            >
              {location.paciente?.nomePaciente}
            </Text>
          </VStack>

          <VStack
            spacing="1"
            ml="10"
            pl="10"
            align="flex-start"
            borderLeft="1px solid #ABABAB"
          >
            <Text
              color="brand-dark.200"
              fontSize="xs"
              fontWeight="normal"
              fontFamily="Rubik"
            >
              IDADE
            </Text>
            <Text
              color="brand-dark.500"
              fontSize="lg"
              fontWeight="large"
              fontFamily="Rubik"
            >
              {location.paciente?.idade}
            </Text>
          </VStack>

          <VStack
            spacing="1"
            ml="10"
            pl="10"
            align="flex-start"
            borderLeft="1px solid #ABABAB"
          >
            <Text
              color="brand-dark.200"
              fontSize="xs"
              fontWeight="normal"
              fontFamily="Rubik"
            >
              SEXO
            </Text>
            <Text
              color="brand-dark.500"
              fontSize="lg"
              fontWeight="large"
              fontFamily="Rubik"
            >
              {location.paciente?.sexo}
            </Text>
          </VStack>

          <VStack
            spacing="1"
            ml="10"
            pl="10"
            align="flex-start"
            borderLeft="1px solid #ABABAB"
          >
            <Text
              color="brand-dark.200"
              fontSize="xs"
              fontWeight="normal"
              fontFamily="Rubik"
            >
              EMAIL
            </Text>
            <Text
              color="brand-dark.500"
              fontSize="lg"
              fontWeight="large"
              fontFamily="Rubik"
            >
              {location.paciente?.email}
            </Text>
          </VStack>

          <VStack
            spacing="1"
            ml="10"
            pl="10"
            align="flex-start"
            borderLeft="1px solid #ABABAB"
          >
            <Text
              color="brand-dark.200"
              fontSize="xs"
              fontWeight="normal"
              fontFamily="Rubik"
            >
              TELEFONE
            </Text>
            <Text
              color="brand-dark.500"
              fontSize="lg"
              fontWeight="large"
              fontFamily="Rubik"
            >
              {location.paciente?.celular}
            </Text>
          </VStack>

          <VStack spacing="1" ml="20" align="flex-start">
            <Button
              fontSize="sm"
              borderRadius="30"
              bg="#60C2B0"
              color="white"
              onClick={() => setViewPatient(true)}
            >
              <Icon
                as={IoAddOutline}
                fontSize="20"
                color="#fff"
                right="0"
                justifyContent="flex-end"
              />
              INFO
            </Button>
          </VStack>
        </GridItem>
      </Grid>
    );
  }

  return (
    <Box maxHeight="50vh">
      {!isMobile() ? (
        <ConsultationInfo />
      ) : (
        <>
          <Button
            onClick={onOpen}
            leftIcon={<Icon as={IoAdd} fontSize="24px" />}
            colorScheme="teal"
            py="24px"
            bg="#60C2B0"
            color="white"
          >
            Detalhes da consulta
          </Button>

          <Drawer
            isOpen={isOpen}
            onClose={onClose}
            finalFocusRef={openDrawerBtn}
            placement="bottom"
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />

              <DrawerBody py="24px">
                <ConsultationInfo />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      )}

      <Box
        width="100%"
        paddingRight="15px"
        paddingTop="10"
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        gap={4}
        marginLeft="5"
      >
        <DrawerIaChat isFluctuant onMessageCallback={setChatCallbackMessage} />
      </Box>
      <Box display={{ lg: 'flex' }} maxHeight="100vh">
        <Tabs
          variant="unstyled"
          width="100%"
          height="100%"
          marginTop="10"
          defaultIndex={location.monitoring ? 1 : location.exam ? 2 : 0}
        >
          <TabList w="50%">
            {tabsTitles.map(tabTitle => {
              return (
                <Box
                  key={tabTitle}
                  backgroundColor="#fff"
                  mr="5px"
                  borderTopRadius="15"
                  sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
                >
                  <Tab
                    py="16px"
                    px="24px"
                    pr="24px"
                    key={tabTitle}
                    color="#3E3E3E"
                    bg="#ddd"
                    fontSize="lg"
                    fontWeight="400"
                    textTransform="uppercase"
                    h="100%"
                    w="100%"
                    _selected={{
                      bg: '#fff',
                      color: '#807D7D',
                      fontWeight: '600',
                    }}
                    fontFamily="Rubik"
                    borderTopRadius="15"
                  >
                    {tabTitle}
                  </Tab>
                </Box>
              );
            })}
          </TabList>

          <Box
            w="100%"
            h="100vh"
            borderBottomRadius="15"
            borderRightRadius="15"
            backgroundColor="#fff"
            // overflowY="auto"
            // sx={{
            //   '&::-webkit-scrollbar': { width: '6px' },
            //   '&::-webkit-scrollbar-track': { borderRadius: '8px' },
            //   '&::-webkit-scrollbar-thumb': {
            //     borderRadius: '8px',
            //     bg: '#bfbfbf',
            //   },
            // }}
          >
            <TabPanels>
              <TabPanel p="20px" maxHeight="850px" overflow="hidden">
                <Box display={{ lg: 'flex' }}>
                  <HistoryCard
                    paciente={location.paciente}
                    setSelectConsul={SetConsultaSelect}
                    mdSize
                  />
                  <Box
                    width={{ base: '100%', sm: '100%', lg: '70%' }}
                    paddingTop="10"
                    display="flex"
                    flexDirection="column"
                    gap={4}
                    marginLeft="5"
                  >
                    <AnamneseCard
                      paciente={location.paciente}
                      selectConsulta={selectConsulta}
                      Monitoring={location.monitoring}
                    />
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel p="20px" maxHeight="100%" minHeight="100%">
                <Box paddingTop="10" />
                <MonitoringCard patientId={location.paciente?.key} />
              </TabPanel>
              <TabPanel p="20px" maxHeight="100%" minHeight="100%">
                <Box paddingTop="10" />
                <ExamsCard
                  patientId={location.paciente?.key}
                  patient={location.paciente}
                />
              </TabPanel>
            </TabPanels>
          </Box>
        </Tabs>
      </Box>

      <GuidanceModal
        isOpen={!!chatCallbackMessage}
        onClose={() => setChatCallbackMessage(undefined)}
        guidance={chatCallbackMessage}
        patient={location.paciente}
      />

      <Flex>
        <Modal isOpen={modalOpen} onClose={onCloseCancel} size="7xl">
          <ModalOverlay />
          <Box>
            <ModalContent width="90%">
              <ModalCloseButton />
              <ModalBody py={20}>
                {/* <ModalResults values="Resultado" /> */}
              </ModalBody>
            </ModalContent>
          </Box>
        </Modal>
      </Flex>

      <GenericModal
        open={viewPatient}
        title={`Paciente: ${
          location.paciente && location.paciente.nomePaciente
        }`}
        onClose={() => setViewPatient(false)}
        size="xl"
      >
        <ViewPatientConsultInfo patient={location.paciente} />
      </GenericModal>
    </Box>
  );
}
