import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Text,
  useToast,
  VStack,
  List,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Modal,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import registerConfirmationImg from '@/assets/register_confirmed.svg';
import { useEffect, useState } from 'react';
import { SelectInput } from '@/components';
import { useProfessionalStore, useUserStore } from '@/stores';
import { useInstanceQuery, useUnidadesIdQuery } from '@/hooks';

import { api } from '@/lib';
import { SearchIcon } from '@chakra-ui/icons';
import { SendMessageModal } from '@/features/historic/components/TableSection/Modal/SendMessageModal';

type CurrentPatient = {
  cpf: string;
  id: string;
  nome: string;
  associado?: boolean;
};

interface RegisterInstanceFormProps {
  onCancel: () => void;
}

export function NewMessagesForm({ onCancel }: RegisterInstanceFormProps) {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [formStep, setFormStep] = useState(0);
  const [inputFocus, setInputFocus] = useState(false);
  const [namePatient, setNamePatient] = useState('');
  const [patientsInput, setPatientsInput] = useState<
    CurrentPatient[] | undefined
  >([]);
  const [patientsInputBackup, setPatientsInputBackup] = useState<
    CurrentPatient[] | undefined
  >([]);
  const [patientSelected, setSelected] = useState<CurrentPatient | undefined>({
    cpf: '',
    id: '',
    nome: '',
  });
  const [hasValueDigitized, setHasValueDigitized] = useState(false);
  const { data: instances = [], refetch: refreshInstance } = useInstanceQuery();
  const [instanciaId, setInstanciaId] = useState(instances[0]?.value);
  const { data: unidades = [] } = useUnidadesIdQuery(instanciaId);
  const [unidadeId, setUnidadeId] = useState('');
  const { professional } = useProfessionalStore();

  const user = useUserStore(state => state.user);

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  function onCloseCancel() {
    setSelected({
      cpf: '',
      id: '',
      nome: '',
    });
    setNamePatient('');
    setModalOpen(false);
  }

  const setDataContent = async () => {
    const params = {
      usuarioId: user?.type === 'medico' ? user?.id : professional?.idMedico,
      idUnidade: unidadeId,
    };

    if (unidadeId) {
      await api
        .get('/paciente/obter-paciente-profissional-paginado', { params })
        .then(response => {
          setPatientsInput(response.data.data);
          setPatientsInputBackup(response.data.data);
        })
        .catch(e => {
          setPatientsInput([]);
          setPatientsInputBackup([]);
        });
    }
  };

  useEffect(() => {
    setSelected({
      cpf: '',
      id: '',
      nome: '',
    });
    setNamePatient('');
    setDataContent();
  }, [unidadeId]);

  const changeNamePatient = (event: any) => {
    setNamePatient(event.target.value);
    setHasValueDigitized(true);

    if (!event.target.value) {
      setPatientsInput(patientsInputBackup);
      setHasValueDigitized(false);
      return;
    }

    const patientsFiltered = patientsInput?.filter(patient => {
      return patient.nome
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setPatientsInput(patientsFiltered);
  };

  const selectPatient = (patient: CurrentPatient) => () => {
    if (!patient.cpf) {
      toast({
        title:
          'Paciente não autorizado. Acesse o app e conclua cadastro para enviar mensagens.',
        status: 'error',
      });
      return;
    }

    const medicoId =
      user?.type === 'medico' ? user?.id : professional?.idMedico;
    setSelected(patient);
    setNamePatient(patient.nome);
    setInputFocus(false);
  };

  async function SendMessage() {
    if (!patientSelected?.id) {
      toast({
        title: 'Selecione um paciente.',
        status: 'error',
      });
      return;
    }
    setModalOpen(true);
  }

  return (
    <Box as="form">
      <VStack spacing="4">
        <SelectInput
          label="Unidades de Saúde"
          options={unidades}
          placeholder="Selecione unidade de saúde"
          onChange={e => setUnidadeId(e.target.value)}
        />
        <Text
          textAlign="left"
          width="100%"
          fontWeight={500}
          color="brand-dark.500"
          fontSize={16}
        >
          Paciente
        </Text>
        <InputGroup mb="8px">
          <Input
            type="text"
            placeholder="Nome do Paciente"
            onChange={changeNamePatient}
            value={namePatient}
            onFocus={() => setInputFocus(true)}
            onBlur={() =>
              setTimeout(() => {
                setInputFocus(false);
              }, 500)
            }
          />
          <InputRightElement
            pointerEvents="none"
            children={<SearchIcon color="brand-dark.300" />}
          />
        </InputGroup>
        {patientsInput && patientsInput.length ? (
          inputFocus ? (
            <List
              mb="6"
              style={{
                maxHeight: '10rem',
                overflowY: 'scroll',
                width: '100%',
              }}
            >
              {patientsInput.map(patient => (
                <Flex
                  style={{ cursor: 'pointer' }}
                  justifyContent="space-between"
                  key={patient.id}
                  onClick={selectPatient(patient)}
                >
                  <Flex p="2" flexDirection="column">
                    <Text fontSize="sm" color="brand-dark.200">
                      {patient.nome.split('@', 1)[0]}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </List>
          ) : (
            patientSelected?.nome && (
              <Flex justifyContent="space-between" width="80%">
                <Flex p="2" flexDirection="column">
                  <Text fontSize="md" color="brand-dark.300">
                    Nome
                  </Text>
                  <Text fontSize="sm" color="brand-dark.200">
                    {patientSelected?.nome}
                  </Text>
                </Flex>
                <Flex p="2" flexDirection="column">
                  <Text fontSize="md" color="brand-dark.300">
                    CPF
                  </Text>
                  <Text fontSize="sm" color="brand-dark.200">
                    {patientSelected?.cpf}
                  </Text>
                </Flex>
              </Flex>
            )
          )
        ) : (
          hasValueDigitized && (
            <List mb="6" style={{ maxHeight: '10rem' }}>
              <Flex flexDirection="column">
                <Text
                  style={{ fontWeight: '500' }}
                  fontSize="sm"
                  color="brand-red.600"
                >
                  Nome inválido ou não cadastrado*
                </Text>
              </Flex>
            </List>
          )
        )}
        <SimpleGrid minChildWidth="120px" spacing="4" w="100%">
          <Button
            type="button"
            variant="outline"
            colorScheme="brand-orange"
            h="45px"
            borderRadius="28px"
            color="brand-orange.300"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
            onClick={() => onCancel()}
          >
            Cancelar
          </Button>
          <Button
            isLoading={false}
            // type="submit"
            colorScheme="brand-green"
            h="45px"
            borderRadius="28px"
            color="white"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
            onClick={SendMessage}
          >
            Continuar
          </Button>
        </SimpleGrid>
      </VStack>
      <Flex w="auto">
        <Modal isOpen={modalOpen} onClose={onCloseCancel} size="xl" isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody px="12" pb="10">
              <SendMessageModal
                onCancel={onCloseCancel}
                dados={{
                  idUsuarioRecebe: patientSelected?.id,
                  nome: patientSelected?.nome,
                  idUsuarioEnvia: user?.id,
                }}
                tipo={1}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
}
