import { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  InputGroup,
  InputLeftElement,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker, Form, Input, Statistic } from 'antd';
import { StepOneSchema } from '@/features/auth/utils';
import {
  FirstAccess,
  SendEmailCode,
  TextInput,
  ValidateCodeEmail,
  useLoginMutation,
} from '@/features/auth';
import { AppVersion, MaskedInput } from '@/components';
import logoImg from '@/assets/logo.png';
import { IoCloseCircle, IoLockClosed, IoPerson } from 'react-icons/io5';
import { FiEye } from 'react-icons/fi';
import { FaCheckCircle } from 'react-icons/fa';
import { getVerifyUsername } from '@/features/registers';
import {
  CForm,
  DateBox,
  PassCheck,
  StepIcon,
  TTextWrapper,
  TextWrapper,
  TimeContent,
  TimeWrapper,
} from './styles/auth.styled';
import CodeInput from './components/CodeInput';
import { Steps } from './components/Steps';

type FirstAccessEmailFormFields = {
  Nome: string;
  Cpf: string;
  Telefone: string;
  DataNascimento: string;
  Email: string;
};

const { Countdown } = Statistic;

export function FirstAccessEmailForm({
  formStep: step,
  SetStep,
  email,
  dataUser,
  setDataUser,
}: any) {
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const [form] = Form.useForm();
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    setError,
    clearErrors,
    setValue,
  } = useForm<FirstAccessEmailFormFields>({
    defaultValues: {
      Nome: '',
      Cpf: '',
      Email: '',
      Telefone: '',
      DataNascimento: '',
    },
    resolver: yupResolver(StepOneSchema),
    shouldFocusError: false,
  });

  const { isLoading } = useLoginMutation();
  const [currentStepStatus, setCurrentStepStatus] = useState<
    'process' | 'wait' | 'finish' | 'error'
  >();

  const [formStep, setFormStep] = useState(1);
  const [code, setCode] = useState('');
  const [codeExp, setCodeExp] = useState(false);
  const [deadline, setDeadline] = useState(Date.now() + 15 * 60 * 1000);
  const [showResendEmail, setShowResendEmail] = useState(false);
  const [check, setCheck] = useState({
    privacyTerms: false,
    ConsentTerms: false,
    communicationTerms: false,
    freeInformedConsent: false,
  });

  const [userName, setUserName] = useState('');
  const [validPass, setValidPass] = useState(false);
  const [validUserName, setValidUserName] = useState(0);
  const [passCheck, setPassCheck] = useState('');
  const [passConfirmCheck, setPassConfirmCheck] = useState('');
  const [viewSenha, setViewSenha] = useState(false);
  const [viewConfirmSenha, setViewConfirmSenha] = useState(false);

  async function onSubmit() {
    if (validUserName === 0 || validUserName === 2) {
      toast({
        title: 'Verifique o usuário.',
        status: 'error',
      });
      return;
    }

    if (!validPass) {
      toast({
        title: 'Senha inválida.',
        status: 'error',
      });
      return;
    }

    const params = {
      Nome: getValues().Nome,
      Cpf: getValues().Cpf,
      Email: step === 2 ? email : getValues().Email,
      CodigoUsuario: step === 2 ? null : email,
      Telefone: getValues().Telefone,
      DataNascimento: getValues().DataNascimento,
      UserName: userName,
      Password: passCheck,
      AceitoComunicacao: check.communicationTerms,
    };
    const res = await FirstAccess(params);
    if (!res.data.success) {
      setCurrentStepStatus('error');
      toast({
        title: res.data.errors[0] || 'Erro ao atualizar dados.',
        status: 'error',
      });
      return;
    }

    setCurrentStepStatus('process');
    setFormStep(5);
  }

  function countDownFinish() {
    setCodeExp(true);
  }

  async function resendEmailCode() {
    const res = await SendEmailCode(email);
    if (!res) {
      setCurrentStepStatus('error');
      toast({
        title: 'Credencial não encontrada no sistema.',
        status: 'error',
      });
      return;
    }

    setDeadline(Date.now() + 15 * 60 * 1000);
    setShowResendEmail(false);
    setCodeExp(false);
    setTimeout(() => {
      setShowResendEmail(true);
    }, 120000);
  }

  async function VerifyCode() {
    const res = await ValidateCodeEmail(email, code);

    if (!res.data.success) {
      setCurrentStepStatus('error');
      toast({
        title: res.data.errors[0] || 'Código inválido.',
        status: 'error',
      });
      return;
    }

    const dados = {
      instancia: res.data.data.instanciaSaude,
      unidade: res.data.data.unidadeSaudeNomes,
      profissional: res.data.data.nomeProfissional,
    };

    setDataUser(dados);
    setValue('Nome', res.data.data?.usuario?.pessoa?.nome);
    setValue('Cpf', res.data.data?.usuario?.pessoa?.cpf);
    setValue('Telefone', res.data.data?.usuario?.pessoa?.celular);
    setValue('DataNascimento', res.data.data?.usuario?.pessoa?.dataNascimento);

    // setUserName(res.data.data?.usuario?.userName);
    // setValidUserName(res.data.data?.usuario?.userName ? 1 : validUserName);

    setCurrentStepStatus('process');
    setFormStep(2);
  }

  const ValidateForm = () => {
    clearErrors();
    if (formStep === 2) {
      if (!getValues().Nome) {
        setError('Nome', {
          type: 'manual',
          message: 'Nome obrigatório',
        });
        return;
      }
      if (!getValues().Cpf) {
        setError('Cpf', {
          type: 'manual',
          message: 'CPF obrigatório',
        });
        return;
      }
      if (!getValues().Email) {
        setError('Email', {
          type: 'manual',
          message: 'Email obrigatório',
        });
        return;
      }
      if (!getValues().Telefone) {
        setError('Telefone', {
          type: 'manual',
          message: 'Telefone obrigatório',
        });
        return;
      }
      if (!getValues().DataNascimento) {
        setError('DataNascimento', {
          type: 'manual',
          message: 'Data de nascimento obrigatório',
        });
        return;
      }
      setCurrentStepStatus('process');
      setFormStep(3);
    }

    if (formStep === 3) {
      if (!check.privacyTerms) {
        toast({
          title: 'Aceite aos Termos e Política de Privacidade',
          status: 'error',
        });
        return;
      }
      if (!check.ConsentTerms) {
        toast({
          title:
            'Aceite aos Termo de Consentimento de Tratamento de Dados Sensíveis',
          status: 'error',
        });
        return;
      }
      setCurrentStepStatus('process');
      setFormStep(4);
    }
  };

  useEffect(() => {
    if (step === 3) {
      setCurrentStepStatus('process');
      setValue('Nome', dataUser?.user?.Nome);
      setValue('Cpf', dataUser?.user?.Cpf);
      setValue('Telefone', dataUser?.user?.Telefone);
      setValue('DataNascimento', dataUser?.user?.DataNascimento);
      setValue('Email', dataUser?.user?.Email);

      //  setUserName(dataUser?.user?.userName);
      // setValidUserName(dataUser?.user?.userName ? 1 : validUserName);
      setFormStep(2);
      return;
    }
    setValue('Email', email);
    resendEmailCode();
  }, [step]);

  useEffect(() => {
    if (
      !!passCheck &&
      passCheck.length > 8 &&
      /\d/.test(passCheck) &&
      /[$#@!]/.test(passCheck) &&
      /[a-z]/.test(passCheck) &&
      /[A-Z]/.test(passCheck) &&
      passCheck === passConfirmCheck
    ) {
      setValidPass(true);
    } else setValidPass(false);
  }, [passCheck, passConfirmCheck]);

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      py={['12', '12', '8']}
      px={['8', '14', '14']}
      w="100%"
      height="100%"
      bg="white"
      borderRadius="0 22px 22px 0"
      direction="column"
      align="center"
      justify="center"
    >
      <Image
        src={logoImg}
        alt="Logo da Dynamic Medic Health"
        mt="auto"
        mb="8"
        w="166px"
        h="77px"
      />
      <VStack spacing="4" w="100%" mb="8">
        <Steps
          current={formStep}
          status={currentStepStatus}
          items={[
            { title: 'Email' },
            { title: 'Código' },
            { title: 'Dados' },
            { title: 'Termos' },
            { title: 'Acesso' },
          ]}
        />
        {formStep === 1 && (
          <CForm
            style={{ margin: '0px 100px' }}
            form={form}
            layout="vertical"
            onFinish={() => {
              setFormStep(2);
            }}
          >
            <TextWrapper>
              <p>Insira o código que foi enviado para o email:</p>
              <p>
                <span style={{ fontWeight: 'bold' }}>{email}</span>
              </p>
            </TextWrapper>

            <TimeWrapper>
              {codeExp ? (
                <span style={{ color: 'red' }}>Código expirado!</span>
              ) : (
                <TimeContent>
                  Seu código expira em:
                  <Countdown
                    value={deadline}
                    onFinish={countDownFinish}
                    format="mm:ss"
                  />
                </TimeContent>
              )}
              <Button
                disabled={!showResendEmail}
                type="button"
                onClick={resendEmailCode}
              >
                Reenviar código
              </Button>
            </TimeWrapper>

            <CodeInput onChange={setCode} status={currentStepStatus} />
          </CForm>
        )}

        {formStep === 2 && (
          <CForm
            style={{ margin: '0px 100px' }}
            form={form}
            layout="vertical"
            onFinish={() => setFormStep(3)}
          >
            <Heading size="sm" mb="2" color="gray.700" textAlign="left">
              Por favor, confirmar seus dados cadastrais:
            </Heading>
            <div style={{ paddingLeft: 10, marginBottom: 5 }}>
              {dataUser.instancia && dataUser.instancia !== '' && (
                <Heading size="sm" mb="2" color="gray.700" textAlign="left">
                  Instância: {dataUser.instancia}
                </Heading>
              )}
              {dataUser.unidade && dataUser.unidade.length > 0 && (
                <Heading size="sm" mb="2" color="gray.700" textAlign="left">
                  Unidades:{' '}
                  {dataUser.unidade.map((name: string) => (
                    <> {name},</>
                  ))}
                </Heading>
              )}

              {dataUser.profissional && (
                <Heading size="sm" mb="2" color="gray.700" textAlign="left">
                  Profissional de Saúde: {dataUser.profissional}
                </Heading>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <TextInput
                mb={2}
                icon={IoPerson}
                type="text"
                placeholder="Nome"
                error={errors.Nome}
                {...register('Nome')}
              />
              <MaskedInput
                mb={2}
                icon={IoPerson}
                control={control}
                mask="cpf"
                error={errors.Cpf}
                placeholder="CPF"
                {...register('Cpf')}
              />
              <TextInput
                mb={2}
                icon={IoPerson}
                type="text"
                placeholder="Email"
                isDisabled
                error={errors.Email}
                {...register('Email')}
              />
              <MaskedInput
                mb={2}
                icon={IoPerson}
                control={control}
                mask="phone"
                error={errors.Telefone}
                placeholder="Ex: (12) 91234-5678"
                {...register('Telefone')}
              />

              <DateBox>
                <InputGroup>
                  <InputLeftElement pl="4" pointerEvents="none">
                    <Icon as={IoPerson} fontSize="20px" color="gray.600" />
                  </InputLeftElement>
                  <Controller
                    control={control}
                    {...register('DataNascimento')}
                    render={({ field: { onChange } }) => (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <DatePicker
                          mb={2}
                          size="large"
                          placeholder="Data de Nascimento"
                          style={{ width: '100%' }}
                          format="DD/MM/YYYY"
                          getPopupContainer={triggerNode => {
                            return triggerNode?.parentNode as HTMLElement;
                          }}
                          onChange={e => {
                            onChange(e?.toDate());
                          }}
                        />
                        {errors?.DataNascimento && (
                          <Text color="red.300">
                            {errors?.DataNascimento.message}
                          </Text>
                        )}
                      </div>
                    )}
                  />
                </InputGroup>
              </DateBox>
            </div>{' '}
          </CForm>
        )}

        {formStep === 3 && (
          <CForm style={{ margin: '0px 100px' }} form={form} layout="vertical">
            <Heading size="sm" mb="6" color="gray.700" textAlign="left">
              Por favor, ler e aceitar aos termos e condições de uso do sistema:
            </Heading>

            <VStack spacing="4" w="100%" mb="8" alignItems="flex-start">
              <Checkbox
                colorScheme="green"
                fontSize="xs"
                fontWeight="medium"
                whiteSpace="nowrap"
                onChange={e =>
                  setCheck({ ...check, privacyTerms: e.target.checked })
                }
              >
                Li e aceito os Termos e Política de Privacidade
              </Checkbox>

              <Checkbox
                width="100%"
                colorScheme="green"
                fontSize="xs"
                fontWeight="medium"
                whiteSpace="nowrap"
                onChange={e =>
                  setCheck({ ...check, ConsentTerms: e.target.checked })
                }
              >
                Li e aceito o Termo de Consentimento de Tratamento de Dados
                Sensíveis
              </Checkbox>
              <Checkbox
                width="100%"
                colorScheme="green"
                fontSize="xs"
                fontWeight="medium"
                whiteSpace="nowrap"
                onChange={e =>
                  setCheck({ ...check, freeInformedConsent: e.target.checked })
                }
              >
                Li e aceito os termos de consentimento de livre e esclarecido
              </Checkbox>
              <Checkbox
                colorScheme="green"
                fontSize="xs"
                fontWeight="medium"
                whiteSpace="nowrap"
                onChange={e =>
                  setCheck({ ...check, communicationTerms: e.target.checked })
                }
              >
                Aceito receber comunicações sobre a DMHealth
              </Checkbox>
            </VStack>
          </CForm>
        )}

        {formStep === 4 && (
          <CForm
            style={{ margin: '0px 100px' }}
            form={form}
            layout="vertical"
            onFinish={() => setFormStep(4)}
          >
            <TTextWrapper>
              <Heading size="sm" mb="6" color="gray.700" textAlign="left">
                Crie uma senha conforme as definições:
              </Heading>
              <p>
                <PassCheck checked={passCheck.length > 8}>
                  Possuir pelo menos 8 caracteres;{' '}
                </PassCheck>

                <PassCheck checked={/\d/.test(passCheck)}>
                  {' '}
                  Possuir pelo menos um número;
                </PassCheck>

                <PassCheck checked={/[$#@!]/.test(passCheck)}>
                  Possuir pelo menos um caracter especial ($,#,@,!,etc);
                </PassCheck>

                <PassCheck checked={/[a-z]/.test(passCheck)}>
                  Possuir ao menos uma letra minúscula;
                </PassCheck>

                <PassCheck checked={/[A-Z]/.test(passCheck)}>
                  Possuir ao menos uma letra maiúscula;
                </PassCheck>

                <PassCheck
                  checked={!!passCheck && passCheck === passConfirmCheck}
                >
                  Confirmar senha.
                </PassCheck>
              </p>
            </TTextWrapper>

            <VStack spacing="4" w="100%" mb="4" mt="4">
              <Grid
                as="section"
                templateColumns={['1fr', '1fr', '1fr', '65% 10% 25%']}
                w="100%"
                bg="white"
                borderRadius="10px"
                overflow="hidden"
                alignItems="flex-start"
              >
                <GridItem>
                  <TextInput
                    icon={IoPerson}
                    type="text"
                    placeholder="Identificação do Usuário"
                    isDisabled={validUserName === 1}
                    value={userName}
                    onChange={e => {
                      setUserName(e.target.value);
                    }}
                  />
                  {validUserName === 2 && (
                    <Text align="left" color="red" w="100%">
                      Username inválido, tente um novo
                    </Text>
                  )}
                </GridItem>

                <GridItem>
                  {validUserName === 1 ? (
                    <StepIcon>
                      <FaCheckCircle color="#7ab49b" />
                    </StepIcon>
                  ) : validUserName === 2 ? (
                    <StepIcon>
                      <IoCloseCircle color="red" />
                    </StepIcon>
                  ) : null}
                </GridItem>
                <GridItem>
                  <Button
                    // type="submit"
                    isLoading={isLoading}
                    disabled={isLoading || validUserName === 1}
                    w="100%"
                    borderRadius="6"
                    bg="#7AB49B"
                    color="white"
                    fontFamily="Inter"
                    fontSize="md"
                    fontWeight="normal"
                    boxShadow="0 1px 3px rgba(0, 0, 0, 0.1)"
                    _hover={{
                      background: '#7AB49B',
                    }}
                    onClick={async () => {
                      const res = await getVerifyUsername(userName);
                      if (!res.data.data) {
                        setValidUserName(1);
                      } else {
                        setValidUserName(2);
                      }
                    }}
                  >
                    Validar
                  </Button>
                </GridItem>
              </Grid>

              <TextInput
                icon={IoLockClosed}
                iconView={FiEye}
                setView={() => setViewSenha(!viewSenha)}
                type={viewSenha ? 'text' : 'password'}
                placeholder="Nova Senha"
                onChange={e => setPassCheck(e.target.value)}
              />
              <TextInput
                icon={IoLockClosed}
                iconView={FiEye}
                setView={() => setViewConfirmSenha(!viewConfirmSenha)}
                type={viewConfirmSenha ? 'text' : 'password'}
                placeholder="Confirmar Senha"
                onChange={e => setPassConfirmCheck(e.target.value)}
              />
            </VStack>
          </CForm>
        )}

        {formStep === 5 && (
          <CForm
            style={{ margin: '0px 100px' }}
            form={form}
            layout="vertical"
            onFinish={() => setFormStep(3)}
          >
            <Heading size="sm" mb="6" color="gray.700" textAlign="left">
              Obrigado {getValues().Nome}, por se cadastrar no sistema DMHealth,
              você receberá por email a confirmação de seu cadastro. Sua
              identificação de usuário não poderá ser modificado, favor guardar
              em local seguro.
            </Heading>
          </CForm>
        )}
      </VStack>{' '}
      {formStep === 5 ? (
        <Button
          // type="submit"
          isLoading={isLoading}
          mb="4"
          w={['100%', '100%', '80%']}
          h="38px"
          borderRadius="6"
          bg="brand-orange.400"
          color="white"
          fontFamily="Inter"
          fontSize="md"
          fontWeight="normal"
          boxShadow="0 1px 3px rgba(0, 0, 0, 0.1)"
          _hover={{
            background: 'brand-orange.300',
          }}
          onClick={() => SetStep(0)}
        >
          Fazer Login
        </Button>
      ) : (
        <>
          <Button
            // type="submit"
            isLoading={isLoading}
            mb="4"
            w={['100%', '100%', '80%']}
            h="38px"
            borderRadius="6"
            bg="brand-orange.400"
            color="white"
            fontFamily="Inter"
            fontSize="md"
            fontWeight="normal"
            boxShadow="0 1px 3px rgba(0, 0, 0, 0.1)"
            _hover={{
              background: 'brand-orange.300',
            }}
            onClick={() => {
              if (formStep === 1) {
                VerifyCode();
                return;
              }
              if (formStep === 4) {
                onSubmit();
              }

              ValidateForm();
            }}
          >
            Próximo
          </Button>
          <Button
            type="button"
            isLoading={isLoading}
            mb="10"
            w={['100%', '100%', '80%']}
            h="38px"
            borderRadius="6"
            bg="white"
            color="brand-orange.400"
            fontFamily="Inter"
            fontSize="md"
            fontWeight="normal"
            boxShadow="0 1px 3px #F18E5A"
            _hover={{
              background: 'brand-orange.100',
              color: 'white',
            }}
            onClick={() => {
              if (formStep > 1 && step === 2) {
                setFormStep(formStep - 1);
                return;
              }
              SetStep(0);
            }}
          >
            Voltar
          </Button>
        </>
      )}
      <AppVersion mt={['8', '8', 'auto']} />
    </Flex>
  );
}
