import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { Patient } from '@/features/schedule/types';
import { useMemo, useState } from 'react';
import { formatDate } from '@/utils';
import { useProfessionalStore, useUserStore } from '@/stores';
import dayjs from 'dayjs';
import { getYearsOld } from '@/utils/getYearsOld';

interface UserInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  patient: Patient | null;
}

const URLTeleconsultation = 'https://portal-hlg-teleconsulta.dmhealth.com.br/';
// const URLTeleconsultation = 'http://localhost:3001/';

export function PatientInfoModal({
  isOpen,
  onClose,
  patient,
}: UserInfoModalProps) {
  const user = useUserStore(state => state.user);
  const professional = useProfessionalStore(state => state.professional);
  const [date, setDate] = useState<any>();
  const cids = patient?.anamneses?.cid;
  const lastConsult = patient?.anamneses?.dataAtendimento;
  let formattedToday = null;

  const nameDoctor = useMemo(() => {
    if (professional?.idMedico === patient?.idDoctor) {
      return professional?.name;
    }

    return '';
  }, [professional, patient]);

  formattedToday = useMemo(() => {
    if (lastConsult) {
      const data = new Date(lastConsult);
      return formatDate(data, "dd 'de' MMMM 'de' yyyy");
    }

    return null;
  }, [lastConsult]);

  const alertColorBox = () => {
    switch (patient!.resultado?.corAlerta ?? 99) {
      case 0:
        return 'green.400';
      case 1:
        return 'yellow.400';
      case 2:
        return 'red.400';
      default:
        return 'white.400';
    }
  };

  const age =
    getYearsOld(
      dayjs(patient?.resource?.dataNascimento).format('DD-MM-YYYY'),
    ) || 0;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent p="8" borderRadius="20px">
        <ModalHeader as={HStack} spacing="6" alignItems="flex-start">
          <Image
            src={patient?.urlPhoto}
            alt={patient?.title}
            borderRadius="full"
            boxSize="70"
          />

          <Box width="80%">
            <Text
              color="brand-dark.300"
              fontSize="2xl"
              fontWeight="medium"
              overflowWrap="break-words"
            >
              {patient?.title}
            </Text>
            <Text mt="1" color="#bfbfbf" fontSize="md" fontWeight="medium">
              {age} anos
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="8">
          {user?.type === 'medico' ? (
            <>
              <Text mb="6" color="brand-dark.200" fontSize="sm">
                <Text as="strong">CID: </Text>
                {cids
                  ? cids?.map((cid, key) => {
                      return key === cids.length - 1
                        ? `${cid.descricao}`
                        : `${cid.descricao} | `;
                    })
                  : null}
              </Text>
              <Text mb="6" color="brand-dark.200" fontSize="sm">
                <Text as="strong">Medicamentos: </Text>

                {patient?.resource?.medicamentos}
              </Text>
              {patient?.resultado && (
                <Box
                  h="45px"
                  borderRadius="10px"
                  color="#000"
                  fontSize="lg"
                  fontWeight="medium"
                  textTransform="uppercase"
                  w="auto"
                  marginY="5"
                  bg={alertColorBox as any}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  border="2px solid #000"
                >
                  {`${patient?.resultado?.nomeQuestionario} |
                  ${patient?.resultado?.nome}`}
                </Box>
              )}
              <Text mb="6" color="brand-dark.200" fontSize="sm">
                <Text as="strong">Orientação: </Text>
                {patient?.anamneses?.conduta?.substring(0, 250)}...
                {/* {patient?.resource.acao} */}
              </Text>
              <Text mb="6" color="brand-dark.200" fontSize="sm">
                <Text as="strong">Última consulta: </Text>
                {formattedToday}
              </Text>
            </>
          ) : (
            <Text mb="6" color="brand-dark.200" fontSize="sm">
              <Text as="strong">Profissional: </Text>
              {nameDoctor}
            </Text>
          )}

          <Text mb="6" color="brand-dark.200" fontSize="sm">
            <Text as="strong">Unidade de Saúde: </Text>
            {patient?.nameUnidade}
          </Text>

          <Flex align="flex-start" justify="space-between">
            <Text mb="6" color="brand-dark.200" fontSize="sm">
              <Text as="strong">Consulta: </Text>
            </Text>

            <Text
              as="strong"
              color="brand-dark.300"
              fontSize="sm"
              textTransform="uppercase"
            >
              {patient?.statusConsulta === 5
                ? 'NEGADO'
                : patient?.statusConsulta === 6
                ? 'CONCLUÍDO'
                : patient?.statusConsulta !== 0
                ? 'PENDENTE'
                : patient?.consultation
                ? 'ON-LINE'
                : 'PRESENCIAL'}
            </Text>
          </Flex>

          <SimpleGrid minChildWidth="120px" spacing="4" w="100%">
            {(patient?.statusConsulta === 0 ||
              (patient?.statusConsulta === 1 && patient?.consultation)) &&
            user?.type === 'medico' ? (
              <a
                href={`${URLTeleconsultation}?UsuarioId=${patient?.idUsuario}&PacienteId=${patient?.idPatient}&AgendaId=${patient?.idAgenda}&ConsultaId=${patient?.idConsultation}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  type="button"
                  colorScheme="brand-green"
                  h="45px"
                  borderRadius="28px"
                  color="white"
                  fontSize="xs"
                  fontWeight="medium"
                  textTransform="uppercase"
                  w="50%"
                  marginTop="20px"
                  marginLeft="25%"
                >
                  Atender
                </Button>
              </a>
            ) : (
              <Button
                onClick={onClose}
                type="button"
                colorScheme="brand-green"
                h="45px"
                borderRadius="28px"
                color="white"
                fontSize="xs"
                fontWeight="medium"
                textTransform="uppercase"
                w="50%"
                marginLeft="25%"
              >
                Ok
              </Button>
            )}
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
