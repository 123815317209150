import {
  getAnamneseMevo,
  useGetConsultas,
} from '@/features/historic/hooks/getConsultas';
import { MONTHS } from '@/features/schedule';
import { useUserStore } from '@/stores';
import {
  Box,
  Input,
  InputGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  Text,
  GridItem,
  Grid,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { TbHandStop } from 'react-icons/tb';
import { FaHeartbeat } from 'react-icons/fa';
import { format, parseISO } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import RadioCard from './RadioCard';
import DadosCard from './DadosCard';

export default function AnamneseCard({
  paciente,
  selectConsulta,
  Monitoring,
}: any) {
  const [consultas, setConsultas] = useState<any[]>();
  const [anamnese, setAnamnese] = useState<any>();
  const [consultasForm, setConsultasForm] = useState<any[]>();
  const user = useUserStore(state => state.user);
  const { mutate: getConsults, isLoading } = useGetConsultas();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  function onCloseCancel() {
    setModalOpen(false);
  }

  const tabsTitles = useMemo(() => ['ANAMNESE', 'ANTECEDENTES'], []);

  const getConsultas = async () => {
    getConsults(paciente?.idPaciente, {
      onSuccess: response => {
        setConsultas(response);
      },
    });
  };

  useEffect(() => {
    getConsultas();
  }, [paciente]);

  const getDateMonth = (num: number) => {
    const name = MONTHS.filter(m => m.numeric === num)[0];

    return name.numeric;
  };

  const getCategories = () => {
    if (consultas) {
      const c = consultas?.reduce((acc, item, i) => {
        return {
          ...acc,
          [getDateMonth(new Date(item.dataPedido).getMonth())]: [
            ...(acc[getDateMonth(new Date(item.dataPedido).getMonth())] ?? []),
            item,
          ],
        };
      }, {});
      setConsultasForm(c);
    }
  };

  useEffect(() => {
    getCategories();
  }, [consultas]);

  const getAnamnesesMevo = async () => {
    const dados = await getAnamneseMevo(
      selectConsulta.id,
      selectConsulta.pacienteId,
    );
    setAnamnese(dados);
  };

  useEffect(() => {
    if (selectConsulta) {
      getAnamnesesMevo();
    }
  }, [selectConsulta]);

  const DadosConsulta = () => {
    return (
      <Grid
        width="100%"
        templateColumns="repeat(12, 1fr)"
        paddingTop="10"
        paddingBottom="10"
      >
        <GridItem display="flex" colSpan={12}>
          <VStack spacing="1" ml="4" align="flex-start">
            <Text
              color="brand-dark.200"
              fontSize="xs"
              fontWeight="normal"
              fontFamily="Rubik"
            >
              PROFISSIONAL
            </Text>
            <Text
              color="brand-dark.500"
              fontSize="lg"
              fontWeight="large"
              fontFamily="Rubik"
            >
              {selectConsulta?.nomeMedico}
            </Text>
          </VStack>

          <VStack
            spacing="1"
            ml="10"
            pl="10"
            align="flex-start"
            borderLeft="1px solid #ABABAB"
          >
            <Text
              color="brand-dark.200"
              fontSize="xs"
              fontWeight="normal"
              fontFamily="Rubik"
            >
              DATA
            </Text>
            <Text
              color="brand-dark.500"
              fontSize="lg"
              fontWeight="large"
              fontFamily="Rubik"
            >
              {format(
                parseISO(selectConsulta?.horasAgenda?.agenda?.data),
                'dd/MM/yyyy',
              )}
            </Text>
          </VStack>

          <VStack
            spacing="1"
            ml="10"
            pl="10"
            align="flex-start"
            borderLeft="1px solid #ABABAB"
          >
            <Text
              color="brand-dark.200"
              fontSize="xs"
              fontWeight="normal"
              fontFamily="Rubik"
            >
              HORÁRIO
            </Text>
            <Text
              color="brand-dark.500"
              fontSize="lg"
              fontWeight="large"
              fontFamily="Rubik"
            >
              {selectConsulta?.horasAgenda?.agenda?.horarioInicio}h
            </Text>
          </VStack>
        </GridItem>
      </Grid>
    );
  };

  return (
    <Tabs variant="unstyled" defaultIndex={Monitoring ? 2 : 0}>
      <TabList w="50%">
        {tabsTitles.map(tabTitle => {
          return (
            <Box
              key={tabTitle}
              backgroundColor="#fff"
              mr="5px"
              border="2px"
              borderColor="#D6D6D6"
              borderBottom="none"
              borderTopRadius="15"
              sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
            >
              <Tab
                py="16px"
                px="24px"
                pr="24px"
                key={tabTitle}
                color="#3E3E3E"
                bg="#ddd"
                fontSize="lg"
                fontWeight="400"
                textTransform="uppercase"
                h="100%"
                w="100%"
                _selected={{
                  bg: '#fff',
                  color: '#807D7D',
                  fontWeight: '600',
                }}
                fontFamily="Rubik"
                borderTopRadius="15"
              >
                {tabTitle}
              </Tab>
            </Box>
          );
        })}
      </TabList>

      <Box
        w="100%"
        height="700px"
        backgroundColor="#fff"
        borderBottomRadius="15"
        borderRightRadius="15"
        overflowY="auto"
        border="2px"
        borderColor="#D6D6D6"
        sx={{
          '&::-webkit-scrollbar': { width: '6px', height: '6px' },
          '&::-webkit-scrollbar-track': { borderRadius: '8px' },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '8px',
            bg: '#bfbfbf',
          },
        }}
      >
        <TabPanels>
          <TabPanel p="20px">
            {selectConsulta ? (
              <>
                <DadosConsulta />
                <RadioCard dados={anamnese} />
              </>
            ) : (
              <Text
                fontSize="lg"
                w="100%"
                color="brand-dark.300"
                fontFamily="Rubik"
                paddingBottom="19"
              >
                NENHUMA CONSULTA SELECIONADA
              </Text>
            )}
          </TabPanel>
          <TabPanel p="20px" maxHeight="100%" minHeight="100%">
            {selectConsulta ? (
              <>
                <DadosConsulta />
                <DadosCard dados={anamnese} />
              </>
            ) : (
              <Text
                fontSize="lg"
                w="100%"
                color="brand-dark.300"
                fontFamily="Rubik"
                paddingBottom="19"
              >
                NENHUMA CONSULTA SELECIONADA
              </Text>
            )}
          </TabPanel>
        </TabPanels>
      </Box>
    </Tabs>
  );
}
