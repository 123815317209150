import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MaskedInput, SelectInput, TextInput } from '@/components';
import { useUserStore } from '@/stores';
import {
  useCouncilsQuery,
  useInstanceQuery,
  useProfessionalsIdQuery,
  useSpecialtiesQuery,
  useUnidadesIdQuery,
} from '@/hooks';
import registerConfirmationImg from '@/assets/register_confirmed.svg';

import { api } from '@/lib';
import { Councils, Unidade, Expertise } from '@/features/registers';
import { IoAdd } from 'react-icons/io5';
import { useListAllPatients } from '@/features/schedule';
import { registerUserSchema } from '../utils';
import { useBrazilStatesQuery } from '../hooks';
import { Expertises } from './RegisterProfessionalForm/Expertises';
import { CouncilsList } from './RegisterProfessionalForm/CouncilsList';
import { UnidadeList } from './RegisterUser/UnidadesList';

type RegisterUserFormFields = {
  nome: string;
  email: string;
  celular: string;
  conselhoId: string;
  numeroConselho: string;
  ufConselho: string;
  professionalId: string;
  especialidade: string;
  rqe: string;
  userType: string;
  registerType: string;
  typeInstance: string;
  instance: string;
  descricao: string;
  conselho: Councils[];
  unidadeId: string;
  instanciaId: string;
};

interface RegisterUserFormProps {
  onCancel: () => void;
}

interface Specialties {
  especialidadeId: string;
  descricao: string;
  rqe: string;
}

export function RegisterUserFormFast({ onCancel }: RegisterUserFormProps) {
  const [formStep, setFormStep] = useState(0);
  const [userType, setUserType] = useState<any>('');
  const [rqeSelect, setRqeSelect] = useState<number>();
  const [specialtyMap, setSpecialtyMap] = useState<any>();
  const [specialtyId, setSpecialtyId] = useState<string>();
  const [specialtyIds, setSpecialtyIds] = useState<Specialties[]>([]);
  const [expertises, setExpertises] = useState<Expertise[]>([]);
  const [conselhos, setConselhos] = useState<Councils[]>([]);
  const [conselhosId, setConselhosId] = useState<any[]>([]);
  const [unidade, setUnidades] = useState<Unidade[]>([]);
  const [unidadesId, setUnidadesId] = useState<any[]>([]);
  const [disableSpe, setDisable] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [codigoUsuario, setCodigoUsuario] = useState(null);

  const [update, setUpdade] = useState<boolean>(true);
  const changeUpdate = () => {
    setUpdade(!update);
  };

  const [path, setPath] = useState<string>('');

  const [professionalId, setProfessionalId] = useState<string>('');

  const user = useUserStore(state => state.user);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    trigger,
    setValue,
    getValues,
  } = useForm<RegisterUserFormFields>({
    defaultValues: {
      nome: '',
      email: '',
      celular: '',
      conselhoId: '',
      numeroConselho: '',
      ufConselho: '',
      professionalId: '',
      especialidade: '',
      rqe: '',
      userType: '',
      registerType: '',
      typeInstance: '',
      instance: '',
      descricao: '',
      unidadeId: '',
      instanciaId: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    resolver: yupResolver(registerUserSchema),
  });

  const { data: instances = [] } = useInstanceQuery();
  const [instanciaId, setInstanciaId] = useState(instances[0]?.value);
  const { data: unidades = [] } = useUnidadesIdQuery(instanciaId);
  const { data: doctors = [] } = useProfessionalsIdQuery(unidadesId[0]);
  const { data: councils = [] } = useCouncilsQuery();
  const { data: specialties = [] } = useSpecialtiesQuery();
  const { refetch } = useListAllPatients('');

  const { data: states = [] } = useBrazilStatesQuery();

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  function handleStepBack() {
    if (formStep === 0) {
      onCancel();
    } else {
      setFormStep(currStep => currStep - 1);
    }
  }

  async function handleStepCompletion() {
    let isFormValid = false;

    if (formStep === 0) {
      isFormValid = await trigger(['nome', 'email', 'celular']);
    }

    if (formStep === 1) {
      isFormValid = await trigger([
        'conselhoId',
        'numeroConselho',
        'ufConselho',
      ]);

      if (isFormValid && conselhos.length === 0) {
        isFormValid = false;

        toast({
          title: 'Adicione ao menos um conselho!',
          status: 'error',
        });
      }
    }

    if (isFormValid) {
      setFormStep(currStep => currStep + 1);
    }
  }

  async function onSubmit(values: RegisterUserFormFields) {
    setLoading(true);
    let isFormValid = false;
    if (
      (formStep === 2 && userType === 'profissional de saúde') ||
      (formStep === 1 &&
        userType !== 'profissional de saúde' &&
        userType !== 'administrador' &&
        userType !== 'gestor master' &&
        userType !== 'operacional')
    ) {
      if (unidadesId.length === 0) {
        isFormValid = false;
        toast({
          title: 'Adicione ao menos uma unidade para associar!',
          status: 'error',
        });
        return;
      }
    }
    let payload: any = {
      // gestorId: user?.gestorMasterId || user?.id,
      usuario: {
        email: values.email,
        pessoa: {
          nome: values.nome,
          celular: values.celular,
        },
      },
      tipoPersona:
        userType === 'profissional de saúde'
          ? 1
          : userType === 'assistente'
          ? 2
          : userType === 'paciente'
          ? 3
          : 0,
    };

    if (formStep === 2 && userType === 'profissional de saúde') {
      const councill = conselhos.map(c => {
        return {
          conselhoId: c.conselhoId,
          nConselho: Number(c.nConselho),
          ufConselho: c.ufConselho,
        };
      });
      payload = {
        ...payload,
        instanciasIds: [values.instanciaId],
        unidadesIds: unidadesId,
        profissional: {
          profissionalSaudeEspecialidade: specialtyIds,
          conselhos: councill,
        },
      };
    } else if (userType === 'paciente') {
      if (!professionalId && user?.type !== 'medico') {
        toast({
          title: 'Selecione um profissional de saúde!',
          status: 'error',
        });
        return;
      }

      payload = {
        ...payload,
        instanciasIds: [values.instanciaId],
        unidadesIds: unidadesId,
        paciente: {
          codigoProfissional: parseInt(
            user?.type === 'medico' ? user?.codigoVinculo : professionalId,
            10,
          ),
        },
      };
    } else if (userType === 'assistente') {
      payload = {
        ...payload,
        instanciasIds: [values.instanciaId],
        unidadesIds: unidadesId,
      };
    } else if (userType === 'gestor') {
      payload = {
        ...payload,
        instanciasIds: [values.instanciaId],
        unidadesIds: unidadesId,
        gestor: {
          tipoGestor: 3,
        },
      };
    } else if (userType === 'gestor master') {
      payload = {
        ...payload,
        instanciasIds: [values.instanciaId],
        gestor: {
          tipoGestor: 2,
        },
      };
    } else if (userType === 'administrador') {
      payload = {
        ...payload,
        gestor: {
          tipoGestor: 0,
        },
      };
    }
    await api
      .post('/usuario', payload)
      .then(response => {
        setCodigoUsuario(response.data.data);
        setFormStep(currStep => currStep + 1);
        refetch();
      })
      .catch(error => {
        const { errors: responseErrors } = error.response.data;
        responseErrors?.map((errorText: string | null | undefined) =>
          toast({
            title: errorText || 'Erro ao cadastrar Admin, tente novamente!',
            status: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    setConselhos(conselhos);
    setSpecialtyIds(specialtyIds);
  });

  const handleChangeSpecialty = useCallback(
    (target: any) => {
      const id = target;
      setSpecialtyId(id);
      setRqeSelect(specialtyMap[id].rqe);
      // setValue('rqe', specialtyMap[id].rqe);
      setValue('especialidade', specialtyMap[id].label);
    },
    [specialtyMap],
  );

  function handleAddExpertise() {
    const [currExpertise, currRqe] = getValues(['especialidade', 'rqe']);

    if (!currExpertise || !currRqe || !specialtyId) {
      toast({
        title: 'Preencha os campos corretamente!',
        status: 'error',
      });

      return;
    }

    if (specialtyIds.length === 3) {
      toast({
        title: 'Somente 3 especialidades podem ser adicionadas!',
        status: 'error',
      });

      return;
    }

    const found = specialtyIds?.find(
      item => item.especialidadeId === specialtyId,
    );
    if (found) {
      toast({
        title: 'Especialidade já adicionada!',
        status: 'error',
      });
      handleChangeSpecialty(specialtyId);
      return;
    }

    const ids = specialtyIds;
    ids.push({
      especialidadeId: specialtyId,
      descricao: currExpertise,
      rqe: currRqe,
    });
    setSpecialtyIds(ids);
    expertises.push({ descricao: currExpertise, rqe: currRqe });
    handleChangeSpecialty(specialtyId);
    changeUpdate();
  }

  useEffect(() => {
    let found2 = 0;

    conselhos?.map(item => {
      if (item.numeroConselho.slice(0, 1) === '9') {
        found2 += 1;
      }
      return null;
    });
    if (found2 === 1) {
      setDisable(false);
    } else {
      setDisable(true);
      specialtyIds?.map(() => specialtyIds.pop());
    }
  });

  const handleChangeCouncils = useCallback(
    (target: any) => {
      const id = target;
      setConselhosId(id);
      setConselhos(conselhos);
    },
    [conselhos],
  );

  function handleAddCouncils() {
    const [id, uf, numero] = getValues([
      'conselhoId',
      'ufConselho',
      'numeroConselho',
    ]);

    if (!id || !uf || !numero) {
      toast({
        title: 'Preencha os campos do conselho corretamente!',
        status: 'error',
      });

      return;
    }

    if (conselhos.length === 3) {
      toast({
        title: 'Somente 3 conselhos podem ser adicionados!',
        status: 'error',
      });

      return;
    }

    const found = conselhos?.find(item => item.conselhoId === id);
    if (found) {
      handleChangeCouncils(id);
      setValue('numeroConselho', numero);
      setValue('ufConselho', uf);

      toast({
        title: 'Conselho já adicionado!',
        status: 'error',
      });
      return;
    }

    const nome = councils?.filter(item => item.value === id);
    const n = nome[0].label;
    const numeroConselho = nome[0].numero;

    const addConselho = conselhos;
    addConselho.push({
      conselhoId: id,
      nConselho: numero,
      numeroConselho,
      ufConselho: uf,
      conselho: {
        id,
        descricao: n,
        numero,
        nome: n,
        ativo: true,
      },
    });
    setConselhos(addConselho);
    handleChangeCouncils(id);

    changeUpdate();

    setValue('numeroConselho', numero);
    setValue('ufConselho', uf);
  }

  useEffect(() => {
    if (specialties.length > 0) {
      const specialtiesMap = specialties.reduce(
        (obj, item) => ({
          ...obj,
          [item.value]: item,
        }),
        {},
      );
      setSpecialtyMap(specialtiesMap);
    }
  }, [specialties]);

  const handleChangeUnidades = useCallback(
    (target: any) => {
      const id = target;
      setUnidades(id);
      setUnidades(unidade);
    },
    [unidade],
  );

  function handleAddUnidades() {
    const [id] = getValues(['unidadeId']);

    if (!id) {
      toast({
        title: 'Selecione uma unidade',
        status: 'error',
      });

      return;
    }

    const found = unidade?.find(item => item.id === id);
    if (found) {
      handleChangeUnidades(id);
      setValue('unidadeId', '');

      toast({
        title: 'Unidade já adicionada!',
        status: 'error',
      });
      return;
    }

    if (unidade.length > 0 && userType !== 'profissional de saúde') {
      toast({
        title: 'Somente 1 unidade pode ser adicionada',
        status: 'error',
      });

      return;
    }

    const nome = unidades?.filter((item: any) => item.value === id);
    const n = nome[0].label;
    unidade.push({
      id,
      nome: n,
    });
    unidadesId.push(id);
    handleChangeUnidades(id);

    changeUpdate();
  }

  const handleChangeProfessional = useCallback((target: any) => {
    setProfessionalId(target.value);
    setValue('professionalId', target.value);
  }, []);

  const handleChangeUserType = useCallback((e: string) => {
    setUnidades([]);
    setUnidadesId([]);
    setUserType(e);
    setValue('userType', e);

    if (e === 'administrador') {
      setValue('instanciaId', instanciaId);
    }

    if (user?.type === 'medico') {
      setValue('professionalId', user?.id);
    }
  }, []);

  function getPersonas() {
    const personas = [
      // {
      //   label: 'Administrador',
      //   value: 'Administrador',
      // },
      // {
      //   label: 'Gestor de Instância',
      //   value: 'Gestor Master',
      // },
      {
        label: 'Gestor de Unidade',
        value: 'Gestor',
      },
      {
        label: 'Assistente',
        value: 'Assistente',
      },
      {
        label: 'Profissional de saúde',
        value: 'Profissional de saúde',
      },
      {
        label: 'Paciente',
        value: 'Paciente',
      },
    ];

    if (user?.type === 'admin') {
      return personas;
    }

    if (user?.type === 'master') {
      return [
        {
          label: 'Gestor de Unidade',
          value: 'Gestor',
        },
        {
          label: 'Assistente',
          value: 'Assistente',
        },
        {
          label: 'Profissional de saúde',
          value: 'Profissional de saúde',
        },
        {
          label: 'Paciente',
          value: 'Paciente',
        },

        // {
        //   label: 'Gestor Master',
        //   value: 'Gestor Master',
        // },
      ];
    }

    if (user?.type === 'gestor') {
      return [
        {
          label: 'Assistente',
          value: 'Assistente',
        },
        {
          label: 'Profissional de saúde',
          value: 'Profissional de saúde',
        },
        {
          label: 'Paciente',
          value: 'Paciente',
        },

        // {
        //   label: 'Gestor',
        //   value: 'Gestor',
        // },
      ];
    }

    if (user?.type === 'medico') {
      return [
        {
          label: 'Paciente',
          value: 'Paciente',
        },
      ];
    }
    if (user?.type === 'secretario' || user?.type === 'assistente') {
      return [
        {
          label: 'Paciente',
          value: 'Paciente',
        },
      ];
    }

    return [];
  }

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      {formStep === 0 && (
        <VStack spacing="4">
          <TextInput
            placeholder="Ex: Mariana Helzberg"
            label="Nome Completo"
            error={errors.nome}
            {...register('nome')}
          />

          <TextInput
            placeholder="Ex: mariana@exemplo.com"
            label="E-mail"
            error={errors.email}
            {...register('email')}
          />

          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <MaskedInput
              name="celular"
              label="Celular"
              control={control}
              mask="phone"
              error={errors.celular}
              placeholder="Ex: (12) 91234-5678"
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <Button
              type="button"
              onClick={handleStepBack}
              variant="outline"
              colorScheme="brand-orange"
              h="45px"
              borderRadius="28px"
              color="brand-orange.300"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Voltar
            </Button>

            <Button
              type="button"
              onClick={handleStepCompletion}
              colorScheme="brand-green"
              h="45px"
              borderRadius="28px"
              color="white"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Continuar
            </Button>
          </SimpleGrid>
        </VStack>
      )}

      {formStep === 1 && (
        <VStack spacing="4">
          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <SelectInput
              options={getPersonas()}
              placeholder="Selecione o tipo de usuário"
              label=""
              error={errors.userType}
              onChange={e => handleChangeUserType(e.target.value.toLowerCase())}
            />
          </SimpleGrid>

          {userType === 'profissional de saúde' && (
            <>
              <SelectInput
                label="Conselho"
                options={councils}
                error={errors.conselhoId}
                placeholder="Conselho"
                {...register('conselhoId')}
              />

              <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
                <TextInput
                  label=""
                  error={errors.numeroConselho}
                  placeholder="Número do conselho"
                  {...register('numeroConselho')}
                />

                <HStack spacing="2" align="flex-end">
                  <SelectInput
                    options={states}
                    placeholder="Escolher estado"
                    label=""
                    error={errors.ufConselho}
                    {...register('ufConselho')}
                  />
                  <IconButton
                    aria-label="Adicionar conselho"
                    icon={<Icon as={IoAdd} color="white" fontSize="20px" />}
                    onClick={handleAddCouncils}
                    colorScheme="brand-green"
                    w="52px"
                    h="52px"
                    borderRadius="14px"
                  />
                </HStack>
              </SimpleGrid>

              <CouncilsList
                councils={conselhos}
                onDelete={(id: any) => {
                  setConselhos(prevState =>
                    prevState.filter(conselho => conselho.conselhoId !== id),
                  );
                }}
              />

              <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
                <SelectInput
                  id="especialidade"
                  label="Especialidade"
                  placeholder="Escolher especialidade"
                  options={specialties}
                  isDisabled={disableSpe}
                  error={errors.especialidade}
                  onChange={({ target }) => {
                    handleChangeSpecialty(target.value);
                  }}
                />

                <HStack spacing="2" align="flex-end">
                  <TextInput
                    label="RQE"
                    error={errors.rqe}
                    isDisabled={disableSpe}
                    placeholder="RQE"
                    {...register('rqe')}
                  />
                  <IconButton
                    aria-label="Adicionar especialidade"
                    icon={<Icon as={IoAdd} color="white" fontSize="20px" />}
                    onClick={handleAddExpertise}
                    isDisabled={disableSpe}
                    colorScheme="brand-green"
                    w="52px"
                    h="52px"
                    borderRadius="14px"
                  />
                </HStack>
              </SimpleGrid>

              {!disableSpe ? (
                <Expertises
                  specialits={specialtyIds}
                  onDelete={rqe => {
                    setSpecialtyIds(prevState =>
                      prevState.filter(expertise => expertise.rqe !== rqe),
                    );
                  }}
                />
              ) : null}
            </>
          )}

          {userType === 'administrador' && null}

          {userType !== 'profissional de saúde' &&
            userType !== 'administrador' && (
              <>
                <SelectInput
                  label="Instância de Saúde"
                  options={instances}
                  placeholder="Selecione instância de saúde"
                  error={errors.instanciaId}
                  onChange={e => {
                    setInstanciaId(e.target.value);
                    setUnidades([]);
                    setUnidadesId([]);
                    setValue('instanciaId', e.target.value);
                  }}
                />

                {userType !== 'gestor master' && userType !== 'operacional' && (
                  <>
                    <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
                      <HStack spacing="2" align="flex-end">
                        <SelectInput
                          label="Unidades de Saúde"
                          options={unidades}
                          placeholder="Selecione unidade de saúde"
                          {...register('unidadeId')}
                        />
                        <IconButton
                          aria-label="Adicionar unidade"
                          icon={
                            <Icon as={IoAdd} color="white" fontSize="20px" />
                          }
                          onClick={handleAddUnidades}
                          colorScheme="brand-green"
                          w="52px"
                          h="52px"
                          borderRadius="14px"
                        />
                      </HStack>
                    </SimpleGrid>
                    <UnidadeList
                      unidades={unidade}
                      onDelete={(id: any) => {
                        setUnidades(prevState =>
                          prevState.filter(uni => uni.id !== id),
                        );
                        setUnidadesId(prevState =>
                          prevState.filter(uni => uni !== id),
                        );
                      }}
                    />
                  </>
                )}
              </>
            )}

          {userType === 'paciente' && (
            <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
              {user?.type === 'medico' ? (
                <TextInput
                  value={user?.name}
                  label="Profissional de Saúde"
                  isDisabled
                />
              ) : (
                <SelectInput
                  options={doctors.filter(
                    (d: { primeiroAcesso: boolean }) => !d.primeiroAcesso,
                  )}
                  placeholder="Associar um Profissional de Saúde"
                  label="Profissional de Saúde"
                  error={errors.professionalId}
                  {...register('professionalId')}
                  onChange={({ target }) => handleChangeProfessional(target)}
                />
              )}
            </SimpleGrid>
          )}

          <SimpleGrid minChildWidth="180px" spacing="4" mt="246px" w="100%">
            <Button
              isDisabled={loading}
              isLoading={loading}
              type="button"
              onClick={handleStepBack}
              variant="outline"
              colorScheme="brand-orange"
              h="45px"
              borderRadius="28px"
              color="brand-orange.300"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Voltar
            </Button>

            {userType === 'profissional de saúde' ? (
              <Button
                isDisabled={loading}
                isLoading={loading}
                type="button"
                onClick={handleStepCompletion}
                colorScheme="brand-green"
                h="45px"
                borderRadius="28px"
                color="white"
                fontSize="xs"
                fontWeight="medium"
                textTransform="uppercase"
              >
                Continuar
              </Button>
            ) : (
              <Button
                isDisabled={loading}
                isLoading={loading}
                type="submit"
                colorScheme="brand-green"
                h="45px"
                borderRadius="28px"
                color="white"
                fontSize="xs"
                fontWeight="medium"
                textTransform="uppercase"
              >
                Cadastrar
              </Button>
            )}
          </SimpleGrid>
        </VStack>
      )}

      {userType === 'profissional de saúde' && formStep === 2 && (
        <VStack spacing="4">
          <SelectInput
            label="Instância de Saúde"
            options={instances}
            placeholder="Selecione instância de saúde"
            error={errors.instanciaId}
            onChange={e => {
              setInstanciaId(e.target.value);
              setUnidades([]);
              setUnidadesId([]);
              setValue('instanciaId', e.target.value);
            }}
          />

          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <HStack spacing="2" align="flex-end">
              <SelectInput
                label="Unidades de Saúde"
                options={unidades}
                placeholder="Selecione unidade de saúde"
                {...register('unidadeId')}
              />
              <IconButton
                aria-label="Adicionar unidade"
                icon={<Icon as={IoAdd} color="white" fontSize="20px" />}
                onClick={handleAddUnidades}
                colorScheme="brand-green"
                w="52px"
                h="52px"
                borderRadius="14px"
              />
            </HStack>
          </SimpleGrid>

          <UnidadeList
            unidades={unidade}
            onDelete={(id: any) => {
              setUnidades(prevState => prevState.filter(uni => uni.id !== id));
              setUnidadesId(prevState => prevState.filter(uni => uni !== id));
            }}
          />

          <SimpleGrid minChildWidth="180px" spacing="4" mt="246px" w="100%">
            <Button
              isDisabled={loading}
              isLoading={loading}
              type="button"
              onClick={handleStepBack}
              variant="outline"
              colorScheme="brand-orange"
              h="45px"
              borderRadius="28px"
              color="brand-orange.300"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Voltar
            </Button>

            <Button
              isDisabled={loading}
              isLoading={loading}
              type="submit"
              colorScheme="brand-green"
              h="45px"
              borderRadius="28px"
              color="white"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Cadastrar
            </Button>
          </SimpleGrid>
        </VStack>
      )}

      {((userType !== 'profissional de saúde' && formStep === 2) ||
        (userType === 'profissional de saúde' && formStep === 3)) && (
        <Flex w="100%" direction="column" align="center">
          <Heading
            color="brand-dark.300"
            fontSize="md"
            fontWeight="medium"
            textAlign="center"
          >
            Cadastro Realizado com Sucesso!
          </Heading>

          {codigoUsuario && (
            <Text
              color="brand-green"
              fontSize="2xl"
              fontWeight="medium"
              textAlign="center"
              mt="6"
            >
              Código de Acesso: {codigoUsuario}
            </Text>
          )}

          <Image
            mt="8"
            mb="4"
            height="40"
            src={registerConfirmationImg}
            alt="Mulher confirmando o sucesso de uma operação em um celular grande"
          />

          <Text
            color="brand-dark.200"
            fontSize="sm"
            fontWeight="normal"
            textAlign="center"
          >
            Você pode editar as configurações na aba de cadastros.
          </Text>

          <Link
            to={path}
            style={{ color: 'inherit', textDecoration: 'none' }}
            replace
          >
            <Button
              type="submit"
              onClick={onCancel}
              colorScheme="brand-green"
              mt="10"
              h="45px"
              borderRadius="28px"
              color="white"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Ir para cadastros
            </Button>
          </Link>
        </Flex>
      )}
    </Box>
  );
}
