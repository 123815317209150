import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Text,
  Grid,
  GridItem,
  Icon,
  Box,
  Button,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';

import { useUserStore } from '@/stores';
import { useInstanciaStore } from '@/stores/instancias';
import { useInstanceQuery } from '@/hooks';
import { GenericModal } from '@/features/registers';
import { ChangeEnvironmentModal } from './components/ChangeEnvironmentModal';

const styles = {
  root: {
    alignItems: 'center',
    background: 'transparent',
    borderRadius: '10px',
    cursor: 'pointer',
    display: 'flex',
    height: '52px',
    marginRight: '50px',
    padding: '0px 10px',
    width: 'fit-content',
  },
};

export function InstanciaSelect() {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  const instRef = useRef(null);
  const [name, setName] = useState<any>('');
  const [openModal, setOpenModal] = useState<any>(null);
  const [instancias, setInstancias] = useState<any[]>([]);
  const [isSelect, setIsSelect] = useState<boolean>();

  const user = useUserStore(state => state.user);
  const setInstancia = useInstanciaStore(state => state.setInstancia);
  const instanceStore = useInstanciaStore(state => state.instancia);

  const { data } = useInstanceQuery();

  useEffect(() => {
    setName(instanceStore?.descricao || '');
  }, [instanceStore]);

  const handleChangeInstancia = useCallback(
    (uni: any) => {
      setOpenModal(uni);
    },
    [setInstancia],
  );

  const handleSelectOpen = useCallback(() => {
    setIsSelect(true);
  }, [isSelect]);

  useEffect(() => {
    if (data && data.length > 0) {
      if (!instanceStore) {
        const pro = data[0];
        setName(pro.label);
        setInstancia({
          id: pro.value,
          descricao: pro.label,
          tipoInstanciaId: pro.tipo,
        });
      }
      setInstancias(data);
    }
  }, [data, setInstancia]);

  useEffect(() => {
    const pageClickEvent = (e: any) => {
      /* @ts-ignore */
      if (instRef.current !== null && !instRef?.current?.contains(e.target)) {
        setIsSelect(!isSelect);
      }
    };
    if (isSelect) {
      window.addEventListener('click', pageClickEvent);
    }
    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isSelect]);

  return (
    <div ref={instRef} style={styles.root}>
      <Flex position="relative">
        <Grid
          width={{ lg: '10rem', sm: '1rem', md: '5rem' }}
          templateColumns="repeat(4, 1fr)"
          onClick={handleSelectOpen}
          alignItems="center"
        >
          {!isMobile && (
            <GridItem
              colSpan={3}
              display="flex"
              alignItems="center"
              flexDirection="column"
              position="relative"
            >
              <Text
                fontFamily="Montserrat"
                fontSize={{ lg: '14', md: '8' }}
                fontStyle="normal"
                fontWeight="600"
              >
                {name || 'Instância:'}
              </Text>
            </GridItem>
          )}
          {user?.type === 'admin' && (
            <GridItem colSpan={1} display="flex">
              <Icon
                as={FiChevronDown}
                color="brand-dark.600"
                fontSize="20px"
                fontWeight="400"
              />
            </GridItem>
          )}
        </Grid>
        {user?.type === 'admin' && isSelect && (
          <Box
            bg="white"
            borderRadius="20px"
            boxShadow="0.8px 0.8px 7px -2px rgba(5, 6, 27, 0.25)"
            marginLeft="-1"
            position="absolute"
            //  padding="5px"
            top="39px"
            zIndex="3"
            width="auto"
          >
            {instancias.map((inst, index) => (
              <Button
                key={inst.value}
                bg="white"
                width="100%"
                color="brand-dark.300"
                fontFamily="Rubik"
                fontWeight="sm"
                padding="20px"
                borderBottom={
                  index === instancias.length - 1 ? 'none' : '1px solid #C2C2C2'
                }
                borderRadius="none"
                onClick={() => handleChangeInstancia(inst)}
              >
                <Text textAlign="left" width="100%">
                  {inst.label}
                </Text>
              </Button>
            ))}
          </Box>
        )}
      </Flex>
      <GenericModal
        open={openModal && openModal?.value}
        title="Alterar instância"
        onClose={() => setOpenModal(false)}
      >
        <ChangeEnvironmentModal
          onChange={() => {
            setName(openModal.label);
            setInstancia({
              id: openModal.value,
              descricao: openModal.label,
              tipoInstanciaId: openModal.tipo,
            });
            setIsSelect(false);
            setOpenModal(null);
          }}
          title="Instância"
          onCancel={() => setOpenModal(null)}
        />
      </GenericModal>
    </div>
  );
}
