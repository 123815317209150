import { api } from '@/lib';

export const useFetchAssociatedProfessionals = async (patientId: string) => {
  try {
    const { data } = await api.get(
      '/usuario/listar-profissional-por-paciente',
      {
        params: {
          id: patientId,
        },
      },
    );

    return data.data;
  } catch (err) {
    return [];
  }
};
