import { VStack, SimpleGrid, Button, Box, useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';

import { MaskedInput, SelectInput, TextInput } from '@/components';
import { ButtonsForm, editAssistentSchema } from '@/features/registers';
import { api, getCep } from '@/lib';
import { useGendersQuery } from '@/hooks';
import { useUserStore } from '@/stores';
import { useBrazilStatesQuery, useBrazilCitiesQuery } from '../../hooks';
import { EditInstances } from '../EditInstances';

interface Props {
  secretary: any;
  onCancel: () => void;
  // onSubmit: (data: any) => void;
}

interface EditFieldsModal {
  nome: string;
  email: string;
  cpf: string;
  dataNascimento: string;
  telefone: string;
  generoId: string;
  cep: string;
  numero: string;
  endereco: string;
  bairro: string;
  cidade: string;
  complemento: string;
  senha?: string;
  celular: string;
  estado: string;
  username: string;
}

export function EditSecretaryForm({ secretary, onCancel }: Props) {
  const { data: genders = [] } = useGendersQuery();
  const [formStep, setFormStep] = useState(0);
  const gender = genders?.filter(item => item?.value === secretary?.generoId);
  const { data: states = [] } = useBrazilStatesQuery();
  const [isLoading, setIsLoading] = useState(false);
  const user = useUserStore(state => state.user);

  const [startYear, startMonth, startDay] = secretary.dataNascimento.split('-');
  const dateNasc = `${startDay.split('T')[0]}${startMonth}${startYear}`;

  const {
    control,
    formState,
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    trigger,
    setError,
    getValues,
  } = useForm<EditFieldsModal>({
    defaultValues: {
      estado: secretary.estado || '',
      nome: secretary.nome || '',
      email: secretary.email || '',
      cpf: secretary.cpf || '',
      generoId: gender[0]?.value || '',
      dataNascimento: dateNasc || '',
      telefone: secretary.telefone || '',
      cep: secretary.cep || '',
      numero: secretary.numero || '',
      endereco: secretary.endereco || '',
      bairro: secretary.bairro || '',
      cidade: secretary.cidade || '',
      complemento: secretary.complemento || '',
      senha: secretary.senha || '',
      celular: secretary.celular || '',
      username: secretary.userName || '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    resolver: yupResolver(editAssistentSchema),
  });

  useEffect(() => {
    setValue('endereco', secretary.endereco);
    setValue('bairro', secretary.bairro);
    setValue('complemento', secretary.complemento);
    setValue('estado', secretary.estado);
    setTimeout(() => {
      setValue('cidade', secretary.cidade);
    }, 1000);
  }, [secretary]);

  const { data: cities = [] } = useBrazilCitiesQuery(watch('estado'));

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const { errors } = formState;

  async function onSubmit(values: any) {
    const date = `${values.dataNascimento.slice(
      2,
      4,
    )}/${values.dataNascimento.slice(0, 2)}/${values.dataNascimento.slice(
      4,
      8,
    )}`;

    const assistent = {
      nome: values.nome,
      email: values.email,
      cpf: values.cpf,
      telefone: values.telefone,
      generoId: values.generoId,
      dataNascimento: new Date(date),
      cep: values.cep,
      numero: values.numero,
      estado: values.estado,
      endereco: values.endereco,
      bairro: values.bairro,
      cidade: values.cidade,
      complemento: values.complemento,
      senha: values.senha,
      celular: values.celular,
      userName: values.username,
    };

    try {
      setIsLoading(true);
      const result: any = await api.put(
        `/assistente/alterar-novo?secretariaId=${secretary.id}`,
        assistent,
      );

      if (result?.statusText === 'OK') {
        toast({
          title: 'Assistente editado com sucesso!',
          status: 'success',
        });
        // window.location.reload();
      }
    } catch (error) {
      toast({
        title: 'Ops algo deu errado!',
        status: 'error',
      });
    } finally {
      if (user?.type === 'admin' || user?.type === 'master') {
        setFormStep(2);
      } else {
        onCancel();
      }
      setIsLoading(false);
    }
  }

  async function checkCEP(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.value) return;

    const cep = e.target.value.replace(/\D/g, '');

    if (cep.length < 8) return;

    const address = await getCep(cep);

    setValue('endereco', address?.data.logradouro);
    setValue('bairro', address?.data.bairro);
    setValue('complemento', address?.data.complemento);
    setValue('estado', address?.data.uf);

    setTimeout(() => {
      setValue('cidade', address?.data.localidade);
    }, 1000);
  }

  function handleStepBack() {
    if (formStep === 0) {
      onCancel();
    } else {
      setFormStep(currStep => currStep - 1);
    }
  }

  async function stepCompletion() {
    let isFormValid = false;

    if (formStep === 0) {
      isFormValid = await trigger([
        'nome',
        'email',
        'cpf',
        'generoId',
        'celular',
        'dataNascimento',
      ]);

      const [username] = getValues(['username']);
      if (!username || username.length < 8 || username.length > 15) {
        setError('username', {
          type: 'manual',
          message: 'Username deve possuir de 8 a 15 caracteres',
        });
        return;
      }

      const regexSpecialChar = /[$#@!]/;
      const hasSpecialChar = regexSpecialChar.test(username);
      if (hasSpecialChar) {
        setError('username', {
          type: 'manual',
          message: 'Username inválido',
        });
        return;
      }

      const userExists = await api.get(
        `/usuario/Usuario-Por-Username?username=${username}`,
      );

      if (username !== secretary.userName && userExists?.data.data) {
        setError('username', {
          type: 'manual',
          message: 'Username não está disponível',
        });
        return;
      }

      isFormValid = true;
    }

    if (isFormValid) {
      setFormStep(formStep + 1);
    }
  }

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      {formStep === 0 && (
        <VStack spacing="4">
          <TextInput
            placeholder="Ex: Mariana Helzberg"
            label="Nome Completo"
            error={errors.nome}
            {...register('nome')}
          />

          <TextInput
            type="email"
            placeholder="Ex: mariana@exemplo.com"
            label="E-mail"
            error={errors.email}
            {...register('email')}
          />

          <SimpleGrid minChildWidth="150px" spacing="4" w="100%">
            <MaskedInput
              name="cpf"
              label="CPF"
              control={control}
              mask="cpf"
              error={errors.cpf}
              placeholder="Ex: 123.456.789-46"
            />
            <MaskedInput
              name="celular"
              label="Celular"
              control={control}
              mask="phone"
              error={errors.celular}
              placeholder="Ex: (12) 99999-9999"
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="150px" spacing="4" w="100%">
            <SelectInput
              options={genders}
              placeholder="Escolher gênero"
              label="Gênero"
              error={errors.generoId}
              {...register('generoId')}
            />

            <MaskedInput
              name="dataNascimento"
              label="Data de Nascimento"
              control={control}
              mask="date"
              error={errors.dataNascimento}
              placeholder="Ex: 01/01/1970"
            />
          </SimpleGrid>

          <TextInput
            placeholder="Ex: mariana.helzberg"
            label="Usuário"
            error={errors.username}
            {...register('username')}
          />

          <ButtonsForm onCancel={onCancel} onSubmit={stepCompletion} />
        </VStack>
      )}

      {formStep === 1 && (
        <VStack spacing="4">
          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <MaskedInput
              name="cep"
              label="CEP"
              control={control}
              mask="zipCode"
              error={errors.cep}
              placeholder="Ex: 12345-678"
              onBlur={checkCEP}
              onChange={checkCEP}
            />

            <SelectInput
              options={states}
              placeholder="Escolher estado"
              label="Estado"
              error={errors.estado}
              {...register('estado')}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <SelectInput
              options={cities}
              placeholder="Escolher cidade"
              label="Cidade"
              error={errors.cidade}
              {...register('cidade')}
            />

            <TextInput
              placeholder="Ex: Bairro Nova Era"
              label="Bairro"
              error={errors.bairro}
              {...register('bairro')}
            />
          </SimpleGrid>

          <TextInput
            placeholder="Ex: Rua José Tavares"
            label="Endereço"
            error={errors.endereco}
            {...register('endereco')}
          />

          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <TextInput
              placeholder="Ex: 123"
              label="Número"
              error={errors.numero}
              {...register('numero')}
            />
            <TextInput
              placeholder="Ex: Próximo ao mercado"
              label="Complemento (opcional)"
              error={errors.complemento}
              {...register('complemento')}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="180px" spacing="4" mt="246px" w="100%">
            <Button
              type="button"
              onClick={handleStepBack}
              variant="outline"
              colorScheme="brand-orange"
              h="45px"
              borderRadius="28px"
              color="brand-orange.300"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Voltar
            </Button>

            <Button
              isLoading={isLoading}
              type="submit"
              colorScheme="brand-green"
              h="45px"
              borderRadius="28px"
              color="white"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Salvar
            </Button>
          </SimpleGrid>
        </VStack>
      )}

      {formStep === 2 && (
        <EditInstances type={4} id={secretary.id} onCancel={onCancel} />
      )}
    </Box>
  );
}
