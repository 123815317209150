import axios from 'axios';

export const api = axios.create({
  // baseURL: 'https://api.dmhealth.com.br/api',
  baseURL: 'https://api-hlg.dmhealth.com.br/api',
});

api.interceptors.request.use(config => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Bearer ${localStorage.getItem('@dmh:token') || ''}`,
  },
}));

export const apiQuestions = axios.create({
  baseURL: 'https://dm-quizzes.azurewebsites.net/api/app',
});

export async function getCep(cep: string) {
  return axios.get(`https://viacep.com.br/ws/${cep}/json/`);
}
