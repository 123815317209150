import { useQuery } from 'react-query';
import { api } from '@/lib';
import { useProfessionalStore, useUserStore } from '@/stores';

interface useFetchPatientsResponse {
  id: string;
  nome: string;
  cpf: string;
  dataNascimento: string;
  celular: string;
  email: string;
  sexo: string;
  urlFoto: string;
  idade: string;
}

const useFetchPatients = () => {
  const user = useUserStore(state => state.user);
  const professional = useProfessionalStore(state => state.professional);

  const { data, isLoading, isError, refetch } = useQuery<
    useFetchPatientsResponse[]
  >({
    queryKey: ['my-patients', user!.id],
    queryFn: async () => {
      const response = await api.get('anamnese/obter-paginado-novo', {
        params: {
          idUsuarioProfissional:
            user?.type !== 'medico' ? professional?.idMedico : user!.id,
        },
      });

      return response.data.data;
    },
  });

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};

export default useFetchPatients;
