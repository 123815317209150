import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Skeleton,
  useDisclosure,
  Image,
} from '@chakra-ui/react';
import { Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useTable,
  usePagination,
  useSortBy,
  useExpanded,
  useGlobalFilter,
} from 'react-table';
import { TableColumnsDetails, usePatientTableQuery } from '@/features/historic';
import DrawerInfos from '@/features/historic/components/Drawer';
import { useProfessionalStore, useUserStore } from '@/stores';
import {
  DoctorIcon,
  GlobalIcon,
  GroupIcon,
  PrecriptionIcon,
  TelegramIcon,
} from '@/assets/icons';
import { Pagination } from '@/features/notifications/components/TableSection/Pagination';
import { ResponseMessageModal } from '@/features/notifications/components/Modal/ResponseMessageModal';
import { TopSection } from '../TopSection';
import { SendMessageModal } from './Modal/SendMessageModal';

export function TableSection() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { mutate: getpatient, isLoading } = usePatientTableQuery();
  const user = useUserStore(state => state.user);
  const professional = useProfessionalStore(state => state.professional);

  const [patient, setPatient] = useState<any>();
  const [dadosOpen, setDados] = useState<any>();
  const [idPaciente, setIdPaciente] = useState<{ id: ''; nome: '' }>();

  const [nextPage, setNextPage] = useState(true);

  const data = useMemo<TableColumnsDetails[]>(() => patient || [], [patient]);

  const columns = useMemo(
    () => [
      {
        header: 'NOME',
        accessor: 'nomePaciente',
      },

      {
        header: 'TELEFONE',
        accessor: 'celular',
      },
      {
        header: 'EMAIL',
        accessor: 'email',
      },
      {
        header: 'IDADE',
        accessor: 'idade',
      },
      {
        header: 'ÚLTIMA CONSULTA',
        accessor: 'ultimaConsulta',
      },
    ],
    [],
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: TopSection,
    }),
    [],
  );

  function onCloseCancel() {
    setModalOpen(false);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    pageOptions,
    gotoPage,
    visibleColumns,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: 5,
      },
    },
    hooks => {
      hooks.visibleColumns.push(originalColumns => [
        ...originalColumns,
        {
          id: 'expander',
          header: '',
          Cell: ({ row }) => (
            <Flex alignItems="center" justifyContent="flex-end" padding="2">
              <Tooltip placement="bottom" title="Visualizar consultas">
                <Image
                  width="20px"
                  height="auto"
                  marginRight="5"
                  src={GroupIcon}
                  onClick={() => {
                    setDados(row.original);
                    onOpen();
                  }}
                />
              </Tooltip>
              <Tooltip placement="bottom" title="Enviar mensagem">
                <Image
                  width="20px"
                  height="auto"
                  marginRight="5"
                  src={TelegramIcon}
                  onClick={() => {
                    setIdPaciente({
                      id: row.original.idPaciente,
                      nome: row.original.nomePaciente,
                    });
                    setModalOpen(true);
                  }}
                />
              </Tooltip>
              <Tooltip placement="bottom" title="Prescrever">
                <Image
                  width="20px"
                  height="auto"
                  marginRight="5"
                  src={PrecriptionIcon}
                  onClick={() => {
                    navigate('/app/prescriptions', {
                      state: {
                        idPaciente: row.original.idPaciente,
                        idMedico: user?.id || '',
                      },
                    });
                  }}
                />
              </Tooltip>
              <Tooltip placement="bottom" title="Exames">
                <Image
                  width="20px"
                  height="auto"
                  marginRight="5"
                  src={DoctorIcon}
                  onClick={() => {
                    navigate('/app/historic', {
                      state: {
                        paciente: row.original,
                        consulta: null,
                        exam: true,
                      },
                    });
                  }}
                />
              </Tooltip>
            </Flex>
          ),
        },
      ]);
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  );

  useEffect(() => {
    const id = user?.type === 'medico' ? user?.id : professional?.idMedico;
    const res = getpatient(id, {
      onSuccess: response => {
        setPatient(response);
      },
    });
  }, [professional]);

  const { pageIndex, globalFilter } = state;

  return (
    <>
      <TopSection filter={globalFilter} setFilter={setGlobalFilter} />
      <DrawerInfos
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        paciente={dadosOpen}
      />

      {isLoading ? (
        <Skeleton mt="8" w="100%" h="600px" border="20px" />
      ) : (
        <Box mt="8" w="100%">
          <TableContainer
            h="600px"
            overflowY="auto"
            sx={{
              '&::-webkit-scrollbar': { width: '8px', height: '8px' },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: 20,
                background: 'brand-dark.200',
              },
            }}
          >
            <Table size="lg" bg="#fff" borderRadius="15px" {...getTableProps()}>
              <Thead h="96px">
                {headerGroups.map(headerGroup => {
                  return (
                    <Tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <Th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps(),
                          )}
                          color="#000000"
                          fontFamily="Rubik"
                          fontSize="lg"
                          fontWeight="normal"
                          textAlign={
                            column.id === 'expander' ? 'center' : 'left'
                          }
                          textTransform="initial"
                        >
                          <>
                            {column.render('header')}

                            <Text as="span" color="#DEDEDE">
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? '▼'
                                  : '▲'
                                : ''}
                            </Text>
                          </>
                        </Th>
                      ))}
                    </Tr>
                  );
                })}
              </Thead>

              <Tbody {...getTableBodyProps()}>
                {page.map((row, id) => {
                  prepareRow(row);
                  return (
                    <Tr
                      //  bg={id % 2 === 0 ? '#ee7e4426' : 'transparent'}
                      borderTop="1px solid #D9D9D9"
                    >
                      {row.cells.map((cell, index) => {
                        return (
                          <Td
                            {...cell.getCellProps()}
                            color="brand-dark.300"
                            fontSize="md"
                            fontWeight="medium"
                            whiteSpace="pre-wrap"
                            cursor="pointer"
                            position="relative"
                            onClick={() => {
                              if (index !== 5) {
                                navigate('/app/historic', {
                                  state: { paciente: cell.row.original },
                                });
                              }
                            }}
                          >
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: `${
                                  index === 5 ? 'flex-end' : 'flex-start'
                                }`,
                              }}
                            >
                              {index === 4 && (
                                <Image
                                  width="20px"
                                  height="auto"
                                  marginRight="2"
                                  src={GlobalIcon}
                                />
                              )}
                              {row.original.recente &&
                                cell.column.id === 'paciente'}
                              {cell.render('Cell')}
                            </span>
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>

          <Flex w="auto">
            <Modal
              isOpen={modalOpen}
              onClose={onCloseCancel}
              size="xl"
              isCentered
            >
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton />
                <ModalBody px="12" pb="8">
                  <SendMessageModal
                    onCancel={onCloseCancel}
                    dados={{
                      idUsuarioRecebe: idPaciente?.id,
                      nome: idPaciente?.nome,
                      idUsuarioEnvia: user?.id,
                    }}
                    tipo={1}
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
          </Flex>

          <Flex
            mt="4"
            w="100%"
            direction={['column', 'column', 'row']}
            align="center"
            justify="space-between"
          >
            <Text color="brand-dark.300" fontSize="sm">
              Página {pageIndex + 1} de {pageOptions.length}
            </Text>

            <Pagination
              currentPage={pageIndex}
              totalCountOfRegisters={data.length}
              canPreviousPage={canPreviousPage}
              canNextPage={nextPage}
              onPageChange={gotoPage}
            />
          </Flex>
        </Box>
      )}
    </>
  );
}
